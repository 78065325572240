import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const MainContainer = styled.div`
  cursor: ${({ blocked }) => !blocked ? 'pointer' : 'not-allowed'};
  display: flex;
  align-items: center;
  filter: ${({ blocked }) => blocked && 'blur(0px)'};
  transition: all 0.1s ease-in;
  &:hover {
    filter: none;
  }
`;

export const Text = styled(Typography)`
  display: block;
  margin: 0;
`;
