export const steps = [
  {
    initialStep: 1,
    color: '#00828a',
    img: '/checkpoints/problem.jpg',
    name: 'Problema',
    steps: [
      'Compreensão dos pontos fortes',
      'Descoberta (insights)',
      'Segmento de clientes',
      'Tamanho do mercado',
      'Compreensão dos clientes',
      'Problema',
      'Validação do problema',
    ],
  },
  {
    initialStep: 8,
    color: '#925ec6',
    img: '/checkpoints/solution.jpg',
    name: 'Solução',
    steps: [
      'Tipo de modelo de negócio',
      'Especificação da solução',
      'Proposta de valor',
      'Posicionamento competitivo',
      'Modelo de geração de receita',
      'Solução',
      'Validação da solução',
    ],
  },
  {
    initialStep: 15,
    color: '#a86701',
    img: '/checkpoints/model.jpg',
    name: 'Modelo de negócios',
    steps: [
      'Modelo econômico previsto',
      'Modelo de operações',
      'Modelo de relacionamento com clientes',
      'Modelo de inovação',
      'Modelo de gestão',
      'Modelo econômico calculado',
      'Modelo de negócios (inicial)',
      'Validação do modelo de negócios',
    ],
  },
  {
    initialStep: 23,
    color: '#00b3a2',
    img: '/checkpoints/final.jpg',
    name: 'Chegada',
    steps: ['Pitch final'],
  },
];
