import { Container } from "@material-ui/core";
import { MdKeyboardArrowRight } from "react-icons/md";
import { FiHome } from "react-icons/fi";
import { useHistory } from "react-router";
import { MainContainer, BreadCrumbItem, Separator } from "./styles";
import useWindowSize from "../../hooks/useWindowSize";
import { useEffect } from "react";
import { useState } from "react";

const Breadcrumb = ({ locations }) => {
  const { width } = useWindowSize();
  const { push } = useHistory();
  const [newlocations, setNewLocations] = useState([]);

  useEffect(() => {
    if (width < 1024) setNewLocations([locations[locations.length - 1]]);
    else setNewLocations(locations);
  }, [width, locations]);

  return (
    <Container>
      <MainContainer>
        <BreadCrumbItem onClick={() => push("/")}>
          <FiHome />
        </BreadCrumbItem>
        <Separator>
          <MdKeyboardArrowRight />
        </Separator>
        {newlocations.map((l) => (
          <>
            <BreadCrumbItem onClick={() => push(l.path)}>
              {l.name}
            </BreadCrumbItem>
            <Separator>
              <MdKeyboardArrowRight />
            </Separator>
          </>
        ))}
      </MainContainer>
    </Container>
  );
};

export default Breadcrumb;
