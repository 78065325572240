import { Button, Container, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import { MenuItem } from "./styles";
import useWindowSize from "../../hooks/useWindowSize";
import { useState } from "react";

const Header = () => {
  const { push } = useHistory();
  const { width } = useWindowSize();
  const [canShowMenu, setCanShowMenu] = useState(false);

  const getName = () => {
    const name = JSON.parse(localStorage.getItem("userInfo")).data.name;
    if (name) {
      let newName = "";
      for (let i = 0; i < name.length && name[i] !== " "; i++)
        newName = newName + name[i];
      newName[0].toUpperCase();
      return ", " + newName + "!";
    }
    return "!";
  };

  return (
    <div style={{ backgroundColor: "#272322", color: "white" }}>
      {width < 1024 ? (
        <div
          onClick={() => setCanShowMenu(!canShowMenu)}
          style={{ display: "flex", flexDirection: "row-reverse" }}
        >
          <div
            style={{
              position: "relative",
              padding: "2em",
              cursor: "pointer",
              transition: 'all 0.2s ease-in',
              transform: canShowMenu ? "" : "rotate(90deg)",
            }}
          >
            |||
          </div>
          {canShowMenu && (
            <Container
              style={{
                backgroundColor: "#272322",
                display: "flex",
                alignItems: "center",
                position: "absolute",
                top: "5em",
                zIndex: 999,
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {localStorage.getItem("userInfo") ? (
                  <>
                    <MenuItem onClick={() => push("/inovate")}>
                      Como inovar
                    </MenuItem>
                    <MenuItem onClick={() => push("/ambition")}>
                      Ambição para inovar
                    </MenuItem>
                    <MenuItem onClick={() => push("/")}>Projetos</MenuItem>
                    {/*  <MenuItem>Fale conosco</MenuItem> */}
                    {localStorage.getItem("projectId") && (
                      <MenuItem onClick={() => push("/saved")}>
                        Padrões
                      </MenuItem>
                    )}
                    <MenuItem>Realização do modelo</MenuItem>
                  </>
                ) : (
                  <>
                    <MenuItem onClick={() => push("/")}>Sobre</MenuItem>
                    <MenuItem>Cases</MenuItem>
                    <MenuItem>Quem somos</MenuItem>
                  </>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "2em",
                  marginTop: "2em",
                  marginBottom: "2em",
                }}
              >
                {localStorage.getItem("userInfo") ? (
                  <>
                    <Typography>Olá{getName()}</Typography>
                    <Button
                      style={{ padding: "0.6em 1em", marginLeft: "1em" }}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        localStorage.clear();
                        // eslint-disable-next-line no-restricted-globals
                        location.href = "/";
                      }}
                    >
                      Sair
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      style={{ padding: "0.6em 5em" }}
                      variant="contained"
                      color="primary"
                      onClick={() => push("/login")}
                    >
                      COMEÇAR
                    </Button>
                  </>
                )}
              </div>
            </Container>
          )}
        </div>
      ) : (
        <Container style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ cursor: "pointer", display: "flex" }}>
            {localStorage.getItem("userInfo") ? (
              <>
                <MenuItem onClick={() => push("/inovate")}>
                  Como inovar
                </MenuItem>
                <MenuItem onClick={() => push("/ambition")}>
                  Ambição para inovar
                </MenuItem>
                <MenuItem onClick={() => push("/")}>Projetos</MenuItem>
                {/*  <MenuItem>Fale conosco</MenuItem> */}
                {localStorage.getItem("projectId") && (
                  <MenuItem onClick={() => push("/saved")}>Padrões</MenuItem>
                )}
                <MenuItem>Realização do modelo</MenuItem>
              </>
            ) : (
              <>
                <MenuItem onClick={() => push("/")}>Sobre</MenuItem>
                <MenuItem>Cases</MenuItem>
                <MenuItem>Quem somos</MenuItem>
              </>
            )}
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            {localStorage.getItem("userInfo") ? (
              <>
                <Typography>Olá{getName()}</Typography>
                <Button
                  style={{ padding: "0.6em 1em", marginLeft: "1em" }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    localStorage.clear();
                    // eslint-disable-next-line no-restricted-globals
                    location.href = "/";
                  }}
                >
                  Sair
                </Button>
              </>
            ) : (
              <>
                <Button
                  style={{ padding: "0.6em 5em" }}
                  variant="contained"
                  color="primary"
                  onClick={() => push("/login")}
                >
                  COMEÇAR
                </Button>
              </>
            )}
          </div>
        </Container>
      )}
    </div>
  );
};

export default Header;
