import { Typography } from '@material-ui/core';
import styled, { keyframes } from 'styled-components';

const fade = keyframes`
  0% {opacity: 0; left: 1em;}
  100% {opacity: 1; left: 0;}
`;

export const MainContainer = styled.div`
  width: 48%;
  box-sizing: border-box;
  padding: 1.6em;
  border: 1px rgba(0, 0, 0, 0.1) solid;
  border-radius: 15px;
  display: flex;
  margin: auto;
  background-color: white;
  margin-bottom: 2em;
  transition: all 0.2s ease-in;
  box-shadow: 4px 6px 10px -8px rgba(0, 0, 0, 0.52);
  animation: ${fade} 1s normal;
  position: relative;
  &:hover {
    transform: scale(1.02);
    background-color: rgb(250, 250, 250);
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  @media screen and (min-width: 768px) and (max-width: 1023px){
    width: 80%;
  }
`;

export const LeftContainer = styled.div`
  width: 67%;
`;

export const RightContainer = styled.div`
  width: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProjectName = styled(Typography)`
  margin: 0;
  padding: 0;
  font-size: 1.4em;
  font-weight: 500;
`;

export const ProjectDescription = styled(Typography)`
  margin: 0;
  padding: 0;
  font-size: 1em;
  font-weight: 400;
`;

export const ProjectConclusion = styled(Typography)`
  margin: 0;
  padding: 0;
  font-size: 2em;
  font-weight: 500;
  color: #00beb1;
`;
