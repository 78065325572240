import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const MainContainer = styled.div`
  background-color: ${({ selected, color }) => (selected ? color : 'grey')};
  width: calc(100% / 3);
  box-sizing: border-box;
  text-align: center;
  padding: 1em 0;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease-in;
`;

export const TabTitle = styled(Typography)`
  color: white;
  font-weight: 500;
  font-size: 1.2em;
`;

export const IconContainer = styled.div`
  position: absolute;
  top: 0;
  left: calc(100% - 14px);
  z-index: 11111;
  display: flex;
  align-items: center;
  height: 100%;
`;
