import { Container } from '@material-ui/core';
import Breadcrumb from '../../components/Breadcrumb';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import MaterialsContainer from '../../components/MaterialsContainer';
import Spacer from '../../components/Spacer';

const text = {
  programa:
    'É um programa voltado à análise de viabilidade de um negócio inovador de base tecnológica, visando avaliar e validar as principais premissas e hipóteses de seu modelo de negócio, sobretudo aquelas relacionadas aos problemas de determinados mercados e segmentos de clientes, ao desenvolvimento de soluções que resolvam esses problemas e ao estudo de viabilidade de seu modelo econômico com o objetivo de tracioná-lo, ou seja, torná-lo repetível e escalável ao longo do tempo, de maneira mais ágil e efetiva',
  metodo:
    'Utilizamos um método estruturado de inovação denominado Jornada da Inovação de Modelo de Negócio. Esse é um método que tem suas origens na experiência corporartiva do seu criador, e evolui com base em pesquisas científicas e experimentações práticas (o método científico que embasou seu desenvolvimento é denominado Design Science Research - DRS).',
  jornada:
    'É um processo sistematizado de inovação de modelo de negócio. É formado por 22 conjuntos de decisões (equivalentes aos 22 passos de uma jornada de inovação) que são tomados de forma disciplinada e iterativa. O objetivo é reduzir a incerteza associada à inovação e, portanto, aumentar a chance de sucesso da empresa e/ou do empreendedor. Assim, a inovação não é uma mera aposta ou uma questão de sorte - e sim, o resultado de um processo estruturado e que utiliza métodos de experimentação científica.',
};

const materials = [
  {
    type: 'ar',
    title: 'Arquitetura do Modelo de Negócio',
    url: 'ER-00.0_Arquitetura do modelo de negocio.jpg',
  },
  {
    type: 'ar',
    title: 'Planilha das premissas e hipóteses',
    url: 'ER-00.2_Planilha das premissas e hipóteses.xlsx',
  },
  {
    type: 'ar',
    title: 'Canvas da entrevista',
    url: 'ER-00.3_Canvas da entrevista.png',
  },
];

const HowToInovate = () => {
  return (
    <div>
      <Header />
      <Breadcrumb locations={[{ path: '/inovate', name: 'Como inovar' }]} />
      <Container>
        <h1>Como inovar?</h1>
      </Container>
      <Spacer />
      <MaterialsContainer
        materials={materials}
        path="arquivos/comoinovar/executar"
      />
      <Spacer size="3em" />

      <div className="home-section home-gray">
        <Container>
          <h2>Adotamos a abordagem de um programa de startup</h2>
          <h2>O que é um programa de startup?</h2>
          <p>{text.programa}</p>
          <h2>Qual é o método utilizado?</h2>
          <p>{text.metodo}</p>
        </Container>
      </div>

      <div className="home-section">
        <Container>
          <h2>JORNADA DA INOVAÇÃO DE MODELO DE NEGÓCIO</h2>
          <p>{text.jornada}</p>
          <img src="/jornada.jpg" className="jornada" alt="" />
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default HowToInovate;
