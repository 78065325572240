import { Container } from '@material-ui/core';
import MaterialsContainer from '../../../../components/MaterialsContainer';
import SectionTitle from '../../../../components/SectionTitle';
import Spacer from '../../../../components/Spacer';
import VideosContainer from '../VideosContainer';
import { SectionContainer } from './styles';

const LearningTab = ({
  selectedVideo,
  stepConfig,
  setSelectedVideo,
  listVideos,
}) => {
  return (
    <>
      <Container>
        <VideosContainer
          selectedVideo={selectedVideo}
          videos={listVideos}
          setSelectedVideo={setSelectedVideo}
        />
      </Container>
      <Spacer size="6em" />
      <SectionContainer>
        <Spacer size="4em" />
        <SectionTitle center>Material de apoio</SectionTitle>
        <Spacer size="5em" />
        <MaterialsContainer
          materials={stepConfig.files.aprender}
          path={`arquivos/${stepConfig.path}/aprender`}
        />
        <Spacer size="5em" />
      </SectionContainer>
    </>
  );
};

export default LearningTab;
