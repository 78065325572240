import styled from 'styled-components';

export const MenuItem = styled.li`
  padding: 1.6em 2em;
  list-style-type: none;
  transition: all .2s ease-in;

  &:hover {
    background-color: #161413;
	transform: scale(1.03);
  }
`;
