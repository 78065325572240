import { useState } from 'react';
import Spacer from '../Spacer';
import { Typography } from '@material-ui/core';
import { BsFillExclamationCircleFill } from 'react-icons/bs';
import { IoShieldCheckmark } from 'react-icons/io5';
import { QuestionButton, QuestionIconContainer, QuestionTitle } from './styles';

const QuestionContainer = ({ question, handleAnswer, answeredQuestions }) => {
  const [selected, setselected] = useState(
    answeredQuestions
      ? answeredQuestions.questionsArray.find((a) => a.title === question.title)
          .answer
      : 'Atividade não realizada'
  );
  return (
    <div>
      <QuestionTitle>{question.title}</QuestionTitle>
      <Spacer />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {['Atividade não realizada', 'Atividade realizada'].map((answer) => (
          <QuestionButton
            onClick={() => {
              setselected(answer);
              handleAnswer(answer, question.number, question.title);
            }}
            selected={selected}
            answer={answer}
          >
            <QuestionIconContainer>
              {selected === 'Atividade não realizada' &&
                selected === answer && (
                  <BsFillExclamationCircleFill size={25} color="red" />
                )}
              {selected === 'Atividade realizada' && selected === answer && (
                <IoShieldCheckmark size={25} color="green" />
              )}
            </QuestionIconContainer>
            <Typography style={{ fontSize: '1.2em', fontWeight: 500 }}>
              {answer}
            </Typography>
          </QuestionButton>
        ))}
      </div>
      <Spacer size="3em" />
    </div>
  );
};

export default QuestionContainer;
