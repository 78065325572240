import { Container, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import SavedCard from '../../components/SavedCard';
import api from '../../services/api';
import allCardsList from '../../utils/cards';

const SavedCards = () => {
  const [projectDetails, setProjectDetails] = useState(false);
  const [fetchingDetails, setFetchingDetails] = useState(false);

  const fetchProjectDetails = () => {
    setFetchingDetails(true);
    api
      .getProjectDetails(localStorage.getItem('projectId'))
      .then((res) => {
        setProjectDetails(res.data);
      })
      .finally(() => setFetchingDetails(false));
  };

  useEffect(fetchProjectDetails, []);

  return (
    <>
      <Header />
      <div className="home-hero-section home-gray">
        <Container>
          <h1>PADRÕES SELECIONADOS</h1>
        </Container>
      </div>
      <Breadcrumb
        locations={[
          { name: 'Projetos', path: '/' },
          {
            name: localStorage.getItem('projectName'),
            path: `/project/${localStorage.getItem('projectId')}`,
          },
          { name: 'Padrões', path: '/saved' },
        ]}
      />
      <div style={{ padding: '1em' }}>
        <Container
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          {!fetchingDetails &&
            projectDetails &&
            (projectDetails.savedCards.length === 0 ? (
              <Typography
                style={{
                  fontSize: '2em',
                  fontWeight: 600,
                  marginBottom: '15em',
                }}
              >
                Não existem padrões selecionados nesse projeto
              </Typography>
            ) : (
              allCardsList
                .filter((c) => projectDetails.savedCards.includes(c.id))
                .map((card) => <SavedCard card={card} />)
            ))}
        </Container>
        <Container
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        ></Container>
      </div>
      <Footer />
    </>
  );
};

export default SavedCards;
