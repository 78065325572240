import { Button, Container, TextField, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import api from '../../services/api';

/* const interesses = [
  'Saúde',
  'Jogos',
  'Direito',
  'Arte',
  'Finanças',
  'Sociedade',
  'Educação',
];

const objetivos = [
  'Novos modelos',
  'Vender ideias',
  'Tirar do papel',
  'Aprender empreendedorismo',
];

const LabelButton = ({ title, selected, onClick }) => {
  return (
    <div
      style={{
        border: '1px solid #2a8bda',
        cursor: 'pointer',
        borderRadius: '5px',
        display: 'inline-block',
        padding: '0.8em',
        marginRight: '2em',
        color: selected ? 'white' : '#2a8bda',
        backgroundColor: !selected ? 'white' : '#2a8bda',
      }}
      onClick={() => onClick(title)}
    >
      <Typography># {title}</Typography>
    </div>
  );
}; */

const Register = () => {
  const [name, setName] = useState();
  const [identification, setIdentification] = useState();
  const [phone, setPhone] = useState();
  const [linkedin, setLinkedin] = useState();
  const [email, setEmail] = useState();
  const [pass, setPass] = useState();
  const [confirmPass, setConfirmPass] = useState();
  const [fetching, setFetching] = useState(false);
  // const [interests, setInterests] = useState('');
  // const [goals, setGoals] = useState('');
  // const [idea, setIdea] = useState('');
  const [allFieldsValidated, setAllFieldsValidated] = useState(false);
  const { push } = useHistory();

  const validateFields = () => {
    if (name === '' || !name) return false;
    if (identification === '' || !identification) return false;
    if (phone === '' || !phone) return false;
    if (email === '' || !email) return false;
    if (pass === '' || !pass || pass.length < 8) return false;
    if (confirmPass !== pass) return false;
    return true;
  };

  useEffect(() => {
    setAllFieldsValidated(validateFields());
    sessionStorage.setItem('userEmail', email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, identification, phone, email, pass, confirmPass]);

  const sendUser = () => {
    const data = {
      name,
      identification,
      phone,
      linkedin,
      email,
      pass,
      // interests,
      // goals,
      // idea,
    };
    setFetching(true);
    api
      .registerNewUser(data)
      .then((res) => {
        push('/pending');
      })
      .finally(() => setFetching(false));
  };

  /* const handleSelectInterest = (interest) => {
    if (interests.includes(interest))
      setInterests(interests.filter((i) => i !== interest));
    else setInterests([...interests, interest]);
  }; */

  /* const handleSelectGoal = (goal) => {
    if (goals.includes(goal)) setGoals(goals.filter((i) => i !== goal));
    else setGoals([...goals, goal]);
  }; */

  return (
    <div>
      <Header />
      <Container style={{ paddingTop: '4em', marginBottom: '4em' }}>
        <div style={{ width: '40%' }}>
          <Typography style={{ fontSize: '1.6em', marginBottom: '1.3em' }}>
            INFORMAÇÕES PESSOAIS
          </Typography>
          <TextField
            error={name !== undefined && name.trim() === ''}
            onBlur={() => name === undefined && setName('')}
            required
            label="Nome"
            variant="outlined"
            onChange={(e) => setName(e.target.value)}
            value={name}
            style={{ display: 'block', marginBottom: '1.5em' }}
            fullWidth
            //disabled={isFetching}
          />
          <TextField
            error={email !== undefined && email.trim() === ''}
            onBlur={() => email === undefined && setEmail('')}
            required
            label="Email"
            variant="outlined"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            style={{ display: 'block', marginBottom: '1.5em' }}
            fullWidth
            //disabled={isFetching}
          />
          <TextField
            error={identification !== undefined && identification.trim() === ''}
            onBlur={() => identification === undefined && setIdentification('')}
            label="Identificação ou nº USP"
            variant="outlined"
            onChange={(e) => setIdentification(e.target.value)}
            value={identification}
            style={{ display: 'block', marginBottom: '1.5em' }}
            fullWidth
            //disabled={isFetching}
          />
          <TextField
            error={phone !== undefined && phone.trim() === ''}
            onBlur={() => phone === undefined && setPhone('')}
            required
            label="Número de celular (xx) XXXXX - XXXX"
            variant="outlined"
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
            style={{ display: 'block', marginBottom: '1.5em' }}
            fullWidth
            //disabled={isFetching}
          />
          <TextField
            // error={linkedin !== undefined && linkedin.trim() === ''}
            onBlur={() => linkedin === undefined && setLinkedin('')}
            label="LinkedIn"
            variant="outlined"
            onChange={(e) => setLinkedin(e.target.value)}
            value={linkedin}
            style={{ display: 'block', marginBottom: '1.5em' }}
            fullWidth
            //disabled={isFetching}
          />
          <TextField
            error={pass !== undefined && pass.trim() === ''}
            onBlur={() => pass === undefined && setPass('')}
            required
            type="password"
            label="Senha (no mínimo 8 caracteres)"
            variant="outlined"
            onChange={(e) => setPass(e.target.value)}
            value={pass}
            style={{ display: 'block', marginBottom: '1.5em' }}
            fullWidth
            //disabled={isFetching}
          />
          <TextField
            error={
              confirmPass !== undefined &&
              confirmPass.trim() === '' &&
              pass.length <= confirmPass.length &&
              pass !== confirmPass
            }
            onBlur={() => confirmPass === undefined && setConfirmPass('')}
            required
            type="password"
            label="Confirmar senha"
            variant="outlined"
            onChange={(e) => setConfirmPass(e.target.value)}
            value={confirmPass}
            style={{ display: 'block', marginBottom: '1.5em' }}
            fullWidth
            //disabled={isFetching}
          />
        </div>
      </Container>
      {/* 
      <Container style={{ marginBottom: '4em' }}>
        <Typography style={{ fontSize: '1.6em', marginBottom: '1em' }}>
          Interesses
        </Typography>
        {interesses.map((interesse, index) => (
          <LabelButton
            title={interesse}
            key={index}
            selected={interests.includes(interesse)}
            onClick={handleSelectInterest}
          />
        ))}
      </Container>
      <Container style={{ marginBottom: '4em' }}>
        <Typography style={{ fontSize: '1.6em', marginBottom: '1em' }}>
          Objetivos
        </Typography>
        {objetivos.map((objetivo, index) => (
          <LabelButton
            title={objetivo}
            key={index}
            selected={goals.includes(objetivo)}
            onClick={handleSelectGoal}
          />
        ))}
      </Container>
      <Container>
        <Typography style={{ fontSize: '1.6em', marginBottom: '1em' }}>
          Tem uma ideia? Conte ela para nós:
        </Typography>
        <TextField
          label="Conte sua ideia"
          variant="outlined"
          onChange={(e) => setIdea(e.target.value)}
          value={idea}
          multiline
          style={{ display: 'block', marginBottom: '1.5em' }}
          fullWidth
          rows={5}
          //disabled={isFetching}
        />
      </Container>
      */}
      <Container
        style={{
          paddingTop: '2em',
          marginBottom: '4em',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button onClick={() => push('/')}>Voltar</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={sendUser}
          disabled={!allFieldsValidated || fetching}
        >
          Cadastre-se
        </Button>
      </Container>
      <Footer />
    </div>
  );
};

export default Register;
