import { Typography } from '@material-ui/core';
import YouTube from 'react-youtube';
import SectionTitle from '../../../../components/SectionTitle';
import Spacer from '../../../../components/Spacer';
import YoutubeThumb from '../../../../components/YoutubeThumb';
import { MainVideoArea, ThumbsArea } from './styles';

const VideosContainer = ({ selectedVideo, videos, setSelectedVideo }) => {
  return (
    <>
      <MainVideoArea>
        {selectedVideo && (
          <YouTube
            videoId={selectedVideo.id}
            opts={{ width: '640', height: '390' }}
          />
        )}
        <div style={{ width: 1, height: 390 }}></div>
        <a
          style={{ textDecoration: 'none', color: 'black' }}
          href={selectedVideo.url}
          target="_blank"
          rel="noreferrer"
        >
          <div
            style={{
              border: '2px gray solid',
              marginLeft: '30px',
              backgroundColor: '#d8d8d8',
              width: '250px',
              height: 'max-content',
              borderRadius: 6,
              boxSizing: 'border-box',
              padding: '1em',
              boxShadow: '4px 6px 10px -8px rgba(0, 0, 0, 0.40)',
            }}
          >
            <Typography style={{ textAlign: 'center' }}>
              Material de apoio
            </Typography>
            <Typography
              style={{
                marginTop: '0.4em',
                textAlign: 'center',
                fontSize: '1.3em',
                fontWeight: 600,
              }}
            >
              {selectedVideo.name}
            </Typography>
            <Typography
              style={{
                textAlign: 'center',
                fontSize: '1em',
                marginTop: '0.6em',
              }}
            >
              (clique aqui para baixar)
            </Typography>
          </div>
        </a>
      </MainVideoArea>
      <Spacer size="3em" />
      {Array.isArray(videos) && videos.length > 1 && (
        <>
          <SectionTitle>Vídeos de apoio</SectionTitle>
          <Spacer size="3em" />
          <ThumbsArea>
            {videos.map((v, i) => (
              <YoutubeThumb
                onClick={setSelectedVideo}
                selected={selectedVideo}
                length={videos.length}
                videoId={v}
                index={i}
              />
            ))}
          </ThumbsArea>
        </>
      )}
    </>
  );
};

export default VideosContainer;
