import { FaRegHourglass } from 'react-icons/fa';

const StepMicro = ({ title, color, children }) => {
  return (
    <div style={{ width: '5em' }}>
      <div
        style={{
          margin: 'auto',
          width: '4em',
          height: '4em',
          borderRadius: '2em',
          backgroundColor: color,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
        }}
      >
        {children || <FaRegHourglass size={18} />}
      </div>
      <p style={{ textAlign: 'center', marginTop: 10 }}>{title}</p>
    </div>
  );
};

export default StepMicro;
