import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router';
import Breadcrumb from '../../components/Breadcrumb';
import Spacer from '../../components/Spacer';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Fetching from '../../components/Fetching';
import SaveQuestionsModal from './components/SaveQuestionsModal';
import TabSelector from './components/TabSelector';
import LearningTab from './components/LearningTab';
import ExecutingTab from './components/ExecutingTab';
import DecidingTab from './components/DecidingTab';
import { MainContainer, StepTitle } from './styles';
import {
  canRenderThisStep,
  getColorOfStep,
  stepIsValid,
} from '../../utils/stepFunctions';
import cardsList from '../../utils/cards';
import api from '../../services/api';
import InvalidStep from './components/InvalidStep';
import ForbiddenStep from './components/ForbbidenStep';
import StepFooter from './components/StepFooter';

const Step = () => {
  const [tabNumber, setTabNumber] = useState(0);
  const [stepConfig, setStepConfig] = useState();
  const [stepCards, setStepCards] = useState([]);
  const [stepQuestions, setStepQuestions] = useState([]);
  const [projectDetails, setProjectDetails] = useState();
  const [interviews, setInterviews] = useState('');
  const [answeredQuestions, setAnsweredQuestions] = useState(false);
  const [executVideo, setExecutVideo] = useState();
  const [selectedVideo, setSelectedVideo] = useState();
  const [fetchingStepDetails, setFetchingStepDetails] = useState(true);
  const [fetchingDetails, setFetchingDetails] = useState();
  const [modalConfirmQuestionsOpen, setModalConfirmQuestionsOpen] =
    useState(false);
  const [questionsList, setQuestionsList] = useState();
  const { id } = useParams();

  const handleAnswerQuestion = (answer, number) => {
    const newQuestions = [...stepQuestions];
    setStepQuestions(
      newQuestions.map((q) => {
        if (q.number === number) return { ...q, answer };
        else return q;
      })
    );
  };

  const fetchProjectDetails = (reload = true) => {
    if (!stepIsValid(id)) return;
    reload && setFetchingDetails(true);
    api
      .getProjectDetails(localStorage.getItem('projectId'))
      .then((res) => {
        setProjectDetails(res.data);
        setAnsweredQuestions(res.data.steps[parseInt(id) - 1].questions);
      })
      .catch(() => {})
      .finally(() => setFetchingDetails(false));
  };

  const fetchStepDetails = () => {
    if (!stepIsValid(id)) return;
    setFetchingStepDetails(true);
    api
      .getStepDetails(id)
      .then((res) => {
        setSelectedVideo(res.data.videos.aprender[0]);
        setExecutVideo(res.data.videos.executar[0]);
        setStepQuestions(res.data.questions);
        setStepConfig(res.data);
      })
      .catch(() => {})
      .finally(() => setFetchingStepDetails(false));
  };

  const handleSaveCard = (cardId) => {
    api.handleSaveCard(cardId).then(() => {
      fetchProjectDetails(false);
    });
  };

  const handleSubmitInterviews = () => {
    api.setInterViewsNumber(parseInt(id), parseInt(interviews)).then(() => {
      fetchProjectDetails(false);
      setInterviews('');
    });
  };

  const handleSubmitQuestions = (canEdit) => {
    const data = new Date();
    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0');
    const ano = data.getFullYear();
    const dataAtual = dia + '/' + mes + '/' + ano;
    const newQuestionsList = {
      ...questionsList,
      date: dataAtual,
      user: JSON.parse(localStorage.getItem('userInfo')).data.name,
    };
    api.registerQuestions(parseInt(id), newQuestionsList).then(() => {
      fetchProjectDetails(false);
      canEdit(false);
    });
  };

  useEffect(() => {
    setStepCards(cardsList.filter((c) => parseInt(c.step) === parseInt(id)));
    fetchStepDetails();
    fetchProjectDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <Header />
      {!stepIsValid(id) ? (
        <InvalidStep />
      ) : fetchingStepDetails || fetchingDetails ? (
        <Fetching />
      ) : !canRenderThisStep(id, projectDetails.validations) ? (
        <ForbiddenStep />
      ) : (
        <>
          <Breadcrumb
            locations={[
              { name: 'Projetos', path: '/' },
              {
                name: projectDetails.name,
                path: `/project/${localStorage.getItem('projectId')}`,
              },
              {
                name: 'Passo ' + id,
                path: `/step/${id}`,
              },
            ]}
          />
          <StepFooter
            projectDetails={projectDetails}
            setTab={setTabNumber}
            validations={projectDetails.validations}
          />
          <Spacer size="4em" />
          <MainContainer>
            <SaveQuestionsModal
              projectDetails={projectDetails}
              modalOpen={modalConfirmQuestionsOpen}
              setIsOpen={setModalConfirmQuestionsOpen}
              answeredQuestions={answeredQuestions}
              stepId={id}
              handleSubmit={handleSubmitQuestions}
              questionsList={questionsList}
            />

            <StepTitle color={getColorOfStep(stepConfig.number)}>
              Passo {stepConfig.name}
            </StepTitle>
            <StepTitle color={getColorOfStep(stepConfig.number)}>
              {stepConfig.title}
            </StepTitle>
            <Spacer size="4em" />
            <TabSelector
              stepId={id}
              onSelect={setTabNumber}
              tabSelected={tabNumber}
            />
            <Spacer size="4em" />
          </MainContainer>
          {tabNumber === 0 && (
            <LearningTab
              selectedVideo={selectedVideo}
              setSelectedVideo={setSelectedVideo}
              stepConfig={stepConfig}
              listVideos={stepConfig.videos.aprender}
            />
          )}
          {tabNumber === 1 && (
            <ExecutingTab
              selectedVideo={executVideo}
              setSelectedVideo={setExecutVideo}
              interviewsOnThisStep={
                projectDetails.steps[parseInt(id)].interviews
              }
              listVideos={stepConfig.videos.executar}
              interviewsNumber={projectDetails.interviews}
              handleSubmitInterviews={handleSubmitInterviews}
              setInterviewsNumber={setInterviews}
              stepConfig={stepConfig}
              interviewsToSubmit={interviews}
              cards={stepCards}
              handleSaveCard={handleSaveCard}
              projectDetails={projectDetails}
            />
          )}
          {tabNumber === 2 && (
            <DecidingTab
              setQuestionsListState={setQuestionsList}
              answeredQuestions={answeredQuestions}
              handleAnswerQuestion={handleAnswerQuestion}
              setModalOpen={setModalConfirmQuestionsOpen}
              stepQuestions={stepQuestions}
            />
          )}
          <Spacer size="5em" />
          <StepFooter
            projectDetails={projectDetails}
            setTab={setTabNumber}
            validations={projectDetails.validations}
          />
          <Spacer size="4em" />
        </>
      )}
      <Footer />
    </>
  );
};

export default Step;
