import { Container, Typography } from "@material-ui/core";
import styled from "styled-components";

export const ProjectHeader = styled(Container)`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
  }
`;

export const HalfHeader = styled.div`
  width: 48%;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const ProjectTitle = styled(Typography)`
  margin: 0;
  padding: 0;
  font-size: 2em;
  font-weight: 600;
`;

export const ProjectScore = styled(Typography)`
  font-weight: 600;
  color: #00b3a1;
  display: inline;
  font-size: ${({ size }) => size || "1.8em"};
`;
