import { Typography } from "@material-ui/core";
import styled from "styled-components";

export const ContainerFlex = styled.div`
  display: flex;
  justify-content: center;
  gap: 2em;
  @media screen and (max-width: 767px) {
    flex-direction: ${({ preventColumn }) =>
      preventColumn ? "row" : "column"};
  }
`;
export const LessonsTitle = styled(Typography)`
  font-size: ${({ small }) => (small ? 1.1 : 1.4)}em;
  font-weight: bold;
  margin-bottom: 1.3em;
  text-align: center;
`;

export const LessonsContainer = styled.div`
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.03);
  // box-shadow: 2px 2px 18px -7px rgba(0, 0, 0, 0.35);
  width: ${({ fullWidth }) => (fullWidth ? 100 : 26)}%;
  padding: 2em;
  border-radius: 20px;
  position: relative;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;
export const LessonsResults = styled(Typography)`
  font-size: ${({ small }) => (small ? 1.4 : 2)}em;
  font-weight: bold;
  text-align: center;
`;
export const LessonsType = styled(Typography)`
  text-align: center;
  max-width: 500px;
`;
export const LessonIconContainer = styled.div`
  position: absolute;
  right: -5px;
  top: -5px;
`;

export const DecisionButtonContainer = styled.div`
  width: 20vh;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  // border: 3px solid #dedede;
  transition: all ease-in-out 0.1s;
  padding: 1.5em;
  box-shadow: 10px 10px 16px #c2c2c2, -10px -10px 16px #ffffff;
  &:hover {
    transform: scale(1.03);
  }

  @media screen and (max-width: 767px) {
    margin: auto;
  }
`;
