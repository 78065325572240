import { Typography } from '@material-ui/core';
import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0% {background-color: #bebebe; color: white;}
  40% {background-color: white; color: #595959;}
  60% {background-color: #bebebe; color: white;}
  100% {background-color: white; color: #595959;}
`;

export const MainContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  margin-top: 2em;
  margin-bottom: 3em;
  background-color: white;
  //box-shadow: 0px 0px 19px -8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`;

export const BreadCrumbItem = styled(Typography)`
  padding: 1em 1em;
  background-color: white;
  border-radius: 5px;
  color: #595959;
  cursor: pointer;
  transition: all 0.1s ease-in;
  &:hover {
    transform: scale(1.03);
    background-color: #bebebe;
    color: white;
  }
  &:active {
    transform: scale(1.1);
  }
  &:last-of-type {
    animation: 1.5s ${pulse} normal;
  }
`;

export const Separator = styled.div`
  display: flex;
  padding: 0 0.6em;
  align-items: center;
  color: #bebebe;
  &:last-child {
    display: none;
  }
`;
