import styled from "styled-components";

export const MainButton = styled.div`
  width: calc(25% - 15px);
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  padding: 1.7em 1em;
  border-radius: 20px;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  box-shadow: 4px 6px 10px -8px rgba(0, 0, 0, 0.4);
  border: ${({ selected }) => (selected ? "4px solid #cdcdcd" : "none")};
  position: relative;

  &:hover {
    // transform: scale(1.06);
    background-color: #d8d8d8;
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    width: calc(33% - 13px);
  }

  @media screen and (min-width: 360px) and (max-width: 767px) {
    width: calc(50% - 20px);
  }

  @media screen and (max-width: 359px) {
    width: 100%;
  }
`;
