import { FaCheck, FaPlayCircle, FaRegHourglass } from 'react-icons/fa';
import { BiLock } from 'react-icons/bi';
import { useHistory } from 'react-router';
import Spacer from '../../../../components/Spacer';
import { steps } from '../../../../utils/stepsList';
import { MainContainer, Text } from './styles';

const StepLine = ({ initialStep, line, index, actualStep, projectDetails }) => {
  const { push } = useHistory();

  const passNumber = `${index + initialStep < 10 ? '0' : ''}${
    initialStep + index
  }`;
  const indexStep = index + initialStep - 1;
  const isDone =
    indexStep === 22 ? false : !!projectDetails.steps[indexStep].questions;

  const iconColor = steps.find((s) => s.initialStep === initialStep).color;

  const isBlocked = () => {
    if (indexStep >= 7 && !projectDetails.validations.val1) return true;
    if (indexStep >= 14 && !projectDetails.validations.val2) return true;
    if (indexStep === 22) return true;
    return false;
  };

  const handleClick = () => {
    !isBlocked() && push(`/step/${passNumber}`);
  };

  return (
    <>
      <MainContainer blocked={isBlocked()} onClick={handleClick}>
        <div style={{ marginRight: 19 }}>
          {actualStep === index + initialStep && !isBlocked() ? (
            <FaPlayCircle size={30} color={iconColor} />
          ) : actualStep > index + initialStep || isDone ? (
            <FaCheck color={iconColor} />
          ) : isBlocked() ? (
            <BiLock size={20} />
          ) : (
            <FaRegHourglass size={15} />
          )}
        </div>
        <Text>
          {passNumber}) {line}
        </Text>
      </MainContainer>
      <Spacer />
    </>
  );
};

export default StepLine;
