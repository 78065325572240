import { Typography } from "@material-ui/core";
import { ButtonAccess, MainContainer } from "./styles";

const ToolCard = ({ tool, path }) => {
  return (
    <MainContainer>
      <div
        style={{
          height: "12em",
          backgroundColor: "#ee5b55",
          margin: 0,
          padding: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h2 style={{ textTransform: "uppercase", color: "white" }}>
          {tool.url.slice(-3) === "lsx"
            ? tool.url.slice(-4)
            : tool.url.slice(-3)}
        </h2>
      </div>
      <div
        style={{
          boxSizing: "border-box",
          padding: "1em 1em",
          paddingBottom: "3em",
          backgroundColor: "white",
        }}
      >
        <h3>{tool.title}</h3>

        <Typography>
          <ButtonAccess
            href={`/${path}/${tool.url}`}
            target="_blank"
            rel="noreferrer"
          >
            Acessar
          </ButtonAccess>
        </Typography>
      </div>
    </MainContainer>
  );
};

export default ToolCard;
