import { Button, Container } from '@material-ui/core';
import { useHistory } from 'react-router';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import './home.css';

const text = {
  programa:
    'É um programa voltado à análise de viabilidade de um negócio inovador de base tecnológica, visando avaliar e validar as principais premissas e hipóteses de seu modelo de negócio, sobretudo aquelas relacionadas aos problemas de determinados mercados e segmentos de clientes, ao desenvolvimento de soluções que resolvam esses problemas e ao estudo de viabilidade de seu modelo econômico com o objetivo de tracioná-lo, ou seja, torná-lo repetível e escalável ao longo do tempo, de maneira mais ágil e efetiva',
  metodo:
    'Utilizamos um método estruturado de inovação denominado Jornada da Inovação de Modelo de Negócio. Esse é um método que tem suas origens na experiência corporartiva do seu criador, e evolui com base em pesquisas científicas e experimentações práticas (o método científico que embasou seu desenvolvimento é denominado Design Science Research - DRS).',
  jornada:
    'É um processo sistematizado de inovação de modelo de negócio. É formado por 22 conjuntos de decisões (equivalentes aos 22 passos de uma jornada de inovação) que são tomados de forma disciplinada e iterativa. O objetivo é reduzir a incerteza associada à inovação e, portanto, aumentar a chance de sucesso da empresa e/ou do empreendedor. Assim, a inovação não é uma mera aposta ou uma questão de sorte - e sim, o resultado de um processo estruturado e que utiliza métodos de experimentação científica.',
};

const HomeWithLogin = () => {
  const { push } = useHistory();
  return (
    <div>
      <Header />
      <div className="home-hero-section home-gray">
        <Container>
          <h1 style={{ fontSize: '3em' }}>
            JORNADA DA INOVAÇÃO DE MODELO DE NEGÓCIO
          </h1>
          <h2>
            Processo estruturado de inovação para organizações{' '}
            <strong>estabelecidas</strong> e <strong>startups</strong>
          </h2>
          <Button
            style={{ padding: '0.6em 5em' }}
            variant="contained"
            color="primary"
            onClick={() => push('/login')}
          >
            COMEÇAR
          </Button>
        </Container>
      </div>

      <div className="home-section">
        <Container>
          <h2>JORNADA DA INOVAÇÃO DE MODELO DE NEGÓCIO</h2>
          <p>{text.jornada}</p>
          <img src="/jornada.jpg" className="jornada" alt="" />
        </Container>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '4em',
          paddingBottom: '4em',
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={() => push('/login')}
        >
          COMEÇAR
        </Button>
      </div>
      <Footer />
    </div>
  );
};

export default HomeWithLogin;
