import { Container, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const MainContainer = styled(Container)`
  display: flex;
  justify-content: center;
  gap: 30px;
`;

export const PassContainer = styled.div`
  width: 4vw;
  height: 4vw;
  min-width: 80px;
  min-height: 80px;
  border-radius: 50%;
  box-sizing: border-box;
  background-color: ${({ active, color }) => (active ? color : '#d8d8d8')};
  transform: ${({ active }) => (active ? 'scale(1.2)' : 'none')};
  transition: all 0.16s ease-in;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  box-shadow: 4px 6px 10px -8px rgba(0, 0, 0, 0.72),
    inset 0px 0px 0px 5px
      ${({ pending }) =>
        pending ? 'rgba(0, 0, 0, 0.17)' : 'rgba(0, 0, 0, 0.0)'};
  &:hover {
    background-color: ${({ color }) => color};
    transform: scale(1.1);
    box-shadow: 9px 11px 22px -8px rgba(0, 0, 0, 0.72),
      inset 0px 0px 0px 6px
        ${({ pending }) =>
          pending ? 'rgba(0, 0, 0, 0.17)' : 'rgba(0, 0, 0, 0.0)'};
  }
`;

export const PassText = styled(Typography)`
  font-weight: 600;
  font-size: 1.5em;
  color: white;
`;
