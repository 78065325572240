import { useHistory, useParams } from "react-router";
import useWindowSize from "../../../../hooks/useWindowSize";
import {
  getColorOfStep,
  getMicroStepsList,
} from "../../../../utils/stepFunctions";
import { MainContainer, PassContainer, PassText } from "./styles";

const StepFooter = ({ projectDetails, validations, setTab }) => {
  const { push } = useHistory();
  const { id } = useParams();
  const { width } = useWindowSize();

  return (
    <MainContainer>
      {getMicroStepsList(id, validations, width).map((m) => (
        <PassContainer
          pending={
            parseInt(m) !== 3 &&
            !projectDetails?.steps[parseInt(m) - 1]?.questions
          }
          color={getColorOfStep(m)}
          active={m === id}
          onClick={() => {
            setTab(0);
            push(`/step/${m}`);
          }}
        >
          <PassText>{m}</PassText>
        </PassContainer>
      ))}
    </MainContainer>
  );
};

export default StepFooter;
