import { MainContainer } from './styles';
import { AiOutlineFolderOpen } from 'react-icons/ai';
import { Typography } from '@material-ui/core';

const EmptyProjects = () => {
  return (
    <MainContainer>
      <div>
        <AiOutlineFolderOpen
          style={{ display: 'block', margin: 'auto' }}
          color="#dddddd"
          size={150}
        />
        <Typography variant="h5" style={{ color: '#7d7d7f' }}>
          Nenhum projeto encontrado
        </Typography>
      </div>
    </MainContainer>
  );
};

export default EmptyProjects;
