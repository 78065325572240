import styled from "styled-components";

export const MainVideoArea = styled.div`
  display: flex;
  justify-content: center;
  @media screen and (max-width: 1023px) {
    display: block;
  }
`;

export const ThumbsArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export const DownloadButton = styled.div`
  //background: linear-gradient(145deg, #ffffff, #d9d9d9);
  background-color: rgba(0, 0, 0, 0.04);
  box-shadow: 7.81px 7.81px 20px #c1c1c1, -7.81px -7.81px 20px #ffffff;
  margin-left: 30px;
  border: 3px dashed ${({ color }) => color || "rgba(0, 0, 0, 0.2)"};
  width: 250px;
  height: max-content;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 1em;
  transition: all ease-in 0.1s;
  position: relative;
  &:hover {
    transform: scale(1.03);
    background-color: rgba(0, 0, 0, 0.1);
  }

  @media screen and (max-width: 1023px) {
    margin: auto;
    margin-top: 2em;
    width: 90%;
  }
`;

export const IconDownloadContainer = styled.div`
  position: absolute;
  top: -13px;
  right: -6px;
`;
