import { Typography } from '@material-ui/core';
import { MainButton } from './styles';

const YoutubeThumb = ({ onClick, length, videoId, index, selected }) => {
  return (
    <MainButton
      selected={selected === videoId}
      index={index + 1}
      onClick={() => {
        onClick(false);
        setTimeout(() => {
          onClick(videoId);
        }, 1);
      }}
    >
      <Typography>{videoId.name}</Typography>
    </MainButton>
  );
};

export default YoutubeThumb;
