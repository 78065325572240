const allCardsList = [
  {
    id: '00',
    step: '0',
    nameStep: 'Ambição para inovar',
    number: '0.1.1',
    titulo: 'Especialista',
    text:
      'Atua de maneira independente do grupo, sendo relativamente individualista. É metódico e racional. Principais características: raciocínio lógico, analítico, crítico, realista, racional, especialista em determinado assunto.',
    subtext:
      'Baseado em: Driessen, M.P., & Zwart, P.S. (1999). The entrepreneur scan measuring characteristics and traits of entrepreneurs. Working paper, University of Groningen. Herrmann, N. (1996), The whole brain business book, McGraw-Hill, New York',
  },
  {
    id: '01',
    step: '0',
    nameStep: 'Ambição para inovar',
    number: '0.1.2',
    titulo: 'Gerente',
    text:
      'Atua de maneira relativamente dependente do grupo, sendo parte de uma equipe. É metódico e emocional. Principais características: ordeiro, comportado, formal, sistemático, organizador, conservador, gestor.',
    subtext:
      'Baseado em: Driessen, M.P., & Zwart, P.S. (1999). The entrepreneur scan measuring characteristics and traits of entrepreneurs. Working paper, University of Groningen. Herrmann, N. (1996), The whole brain business book, McGraw-Hill, New York.',
  },
  {
    id: '02',
    step: '0',
    nameStep: 'Ambição para inovar',
    number: '0.1.3',
    titulo: 'Pioneiro',
    text:
      'Atua de maneira independente do grupo, sendo relativamente individualista. É instintivo e racional. Principais características: criativo, intuitivo, curioso, visão sistêmica, gosta de experimentar, de ser pioneiro.',
    subtext:
      'Baseado em: Driessen, M.P., & Zwart, P.S. (1999). The entrepreneur scan measuring characteristics and traits of entrepreneurs. Working paper, University of Groningen. Herrmann, N. (1996), The whole brain business book, McGraw-Hill, New York.',
  },
  {
    id: '03',
    step: '0',
    nameStep: 'Ambição para inovar',
    number: '0.1.4',
    titulo: 'Vendedor',
    text:
      'Atua de maneira relativamente dependente do grupo, sendo parte de uma equipe. É instintivo e emocional. Principais características: sociável, gosta de pessoas, animado, emocional, sensível, vendedor.',
    subtext:
      'Baseado em: Driessen, M.P., & Zwart, P.S. (1999). The entrepreneur scan measuring characteristics and traits of entrepreneurs. Working paper, University of Groningen. Herrmann, N. (1996), The whole brain business book, McGraw-Hill, New York.',
  },
  {
    id: '04',
    step: '1',
    nameStep: 'Compreensão dos pontos fortes',
    number: '1.1.1',
    titulo: 'Infraestrutura',
    text:
      'Considera a posse, experiência, conhecimento e acesso aos recursos de infraestrutura por parte da organização ou do empreendedor. Exemplos: instalações, equipamentos, recursos logísticos.',
    subtext: '',
  },
  {
    id: '05',
    step: '1',
    nameStep: 'Compreensão dos pontos fortes',
    number: '1.1.2',
    titulo: 'Capital natural',
    text:
      'Considera a posse, experiência, conhecimento e acesso aos recursos naturais por parte da organização ou do empreendedor. Exemplos: minas, reservas naturais.',
    subtext: '',
  },
  {
    id: '06',
    step: '1',
    nameStep: 'Compreensão dos pontos fortes',
    number: '1.1.3',
    titulo: 'Capital financeiro',
    text:
      'Considera a posse, experiência, conhecimento e acesso aos recursos financeiros por parte da organização ou do empreendedor. Exemplos: recursos financeiros disponíveis, capacidade de captação de recursos e de financiamento.',
    subtext: '',
  },
  {
    id: '07',
    step: '1',
    nameStep: 'Compreensão dos pontos fortes',
    number: '1.1.4',
    titulo: 'Capital humano',
    text:
      'Considera a posse (ex.: contratos de trabalho), experiência, conhecimento e acesso aos recursos humanos por parte da organização ou do empreendedor. Exemplos: qualificação dos recursos humanos, conhecimento tácito.',
    subtext: '',
  },
  {
    id: '08',
    step: '1',
    nameStep: 'Compreensão dos pontos fortes',
    number: '1.1.5',
    titulo: 'Capital organizacional',
    text:
      'Considera a posse, experiência, conhecimento e acesso aos recursos de gestão por parte da organização ou do empreendedor. Exemplos: sistemas de gestão, certificações, governança corporativa.',
    subtext: '',
  },
  {
    id: '09',
    step: '1',
    nameStep: 'Compreensão dos pontos fortes',
    number: '1.1.6',
    titulo: 'Capital institucional',
    text:
      'Considera a posse (ex.: acordos com universidades), experiência, conhecimento e acesso aos recursos institucionais por parte da organização ou do empreendedor. Exemplos:  relacionamento com stakeholders, comunidade, governo, universidades.',
    subtext: '',
  },
  {
    id: '10',
    step: '1',
    nameStep: 'Compreensão dos pontos fortes',
    number: '1.1.7',
    titulo: 'Capital de fornecimento',
    text:
      'Considera a posse (ex.: contratos de fornecimento), experiência, conhecimento e acesso aos recursos de fornecimento por parte da organização ou do empreendedor. Exemplos: relacionamentos com fornecedores e parceiros estratégicos.',
    subtext: '',
  },
  {
    id: '11',
    step: '1',
    nameStep: 'Compreensão dos pontos fortes',
    number: '1.1.8',
    titulo: 'Capital de distribuição',
    text:
      'Considera a posse (ex.: canais próprios de distribuição), experiência, conhecimento e acesso aos recursos de distribuição por parte da organização ou do empreendedor. Exemplos: estrutura dos canais de distribuição.',
    subtext: '',
  },
  {
    id: '12',
    step: '1',
    nameStep: 'Compreensão dos pontos fortes',
    number: '1.1.9',
    titulo: 'Capital de processos',
    text:
      'Considera a posse, experiência, conhecimento e acesso aos recursos e tecnologias de processos de transformação por parte da organização ou do empreendedor. Exemplos: processos de fabricação e de atendimento.',
    subtext: '',
  },
  {
    id: '13',
    step: '1',
    nameStep: 'Compreensão dos pontos fortes',
    number: '1.1.10',
    titulo: 'Capital de inovação',
    text:
      'Considera a posse (ex.: patentes, direitos autorais), experiência, conhecimento e acesso aos recursos de inovação por parte da organização ou do empreendedor. Exemplos: propriedade intelectual, recursos de P&D.',
    subtext: '',
  },
  {
    id: '14',
    step: '1',
    nameStep: 'Compreensão dos pontos fortes',
    number: '1.1.11',
    titulo: 'Capital de clientes',
    text:
      'Considera a posse (ex.: contratos comerciais), experiência, conhecimento e acesso aos clientes (usuários e compradores) por parte da organização ou do empreendedor. Exemplos: relacionamentos com clientes e consumidores, carteira de clientes.',
    subtext: '',
  },
  {
    id: '15',
    step: '1',
    nameStep: 'Compreensão dos pontos fortes',
    number: '1.1.12',
    titulo: 'Capital de marcas e reputação',
    text:
      'Considera a posse (ex.: marcas), experiência, conhecimento e acesso aos recursos que proveem reputação à organização ou ao empreendedor. Exemplos: marcas próprias, franquias.',
    subtext: '',
  },
  {
    id: '16',
    step: '3',
    nameStep: 'Segmentos de clientes (B2C)',
    number: '3.1.1',
    titulo: 'Perfil demográfico',
    text:
      'Foco nos clientes com características demográficas similares (ex.: faixa etária, gênero, localização, ocupação, renda média). Os produtos e serviços são projetados para atender as necessidades específicas do segmento. Exemplos: Prevent Senior, Cora Residencial, Curves.',
    subtext:
      'Baseado em: Kotler, P. & Keller, K.L. (2012). Marketing management. 14th. Ed. Upper Saddle River: Prentice Hall.',
  },
  {
    id: '17',
    step: '3',
    nameStep: 'Segmentos de clientes (B2C)',
    number: '3.1.2',
    titulo: 'Clientes de alto luxo',
    text:
      'Foco nos clientes situados no alto da pirâmide social. Produtos e serviços com elevados padrões de qualidade associados a atributos aspiracionais que remetem a prestígio e exclusividade. Preços elevados, condizentes com a proposta de valor de alto luxo. Exemplos: Louis Vuitton, Mont Blanc, Fasano.',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 326-329. (#53 Ultimate luxury).',
  },
  {
    id: '18',
    step: '3',
    nameStep: 'Segmentos de clientes (B2C)',
    number: '3.1.3',
    titulo: 'Clientes de padrão intermediário',
    text:
      'Foco nos clientes situados na faixa intermediária da pirâmide social. Produtos e serviços com padrões de qualidade e preços intermediários. Exemplos: Itau Personalité, Bradesco Prime.',
    subtext: '',
  },
  {
    id: '19',
    step: '3',
    nameStep: 'Segmentos de clientes (B2C)',
    number: '3.1.4',
    titulo: 'Clientes da base da pirâmide',
    text:
      'Foco nos clientes situados na base da pirâmide social. Produtos e serviços de baixo custo (para a empresa) e baixo preço (para o cliente). Exemplos: Aravind.',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 312-315. (#50 Target the poor).',
  },
  {
    id: '20',
    step: '3',
    nameStep: 'Segmentos de clientes (B2C)',
    number: '3.1.5',
    titulo: 'Negócio inclusivo',
    text:
      'Foco na inclusão de pessoas (ex.: de baixa renda, com deficiência) nos processos de produção, fornecimento ou distribuição. Exemplos: Noisinho da Silva, Rede Asta.',
    subtext: '',
  },
  {
    id: '21',
    step: '3',
    nameStep: 'Segmentos de clientes (B2C)',
    number: '3.1.6',
    titulo: 'Perfil epidemiológico',
    text:
      'Foco nos clientes com características epidemiológicas similares (ex.: diabéticos, pacientes oncológicos, pacientes com depressão). Os produtos e serviços são projetados para atender as necessidades específicas do segmento. Exemplos: AC Camargo Cancer Center, AACD, GRAAC, Patients Like Me.',
    subtext: '',
  },
  {
    id: '22',
    step: '3',
    nameStep: 'Segmentos de clientes (B2C)',
    number: '3.1.7',
    titulo: 'Perfil psicográfico',
    text:
      'Foco nos clientes com características psicográficas similares (ex.: valores, preferências, atitudes, personalidade, estilo de vida). Os produtos e serviços são projetados para atender as necessidades específicas do segmento. Exemplos: Harley Davidson.',
    subtext:
      'Baseado em: Kotler, P. & Keller, K.L. (2012). Marketing management. 14th. Ed. Upper Saddle River: Prentice Hall.',
  },
  {
    id: '23',
    step: '3',
    nameStep: 'Segmentos de clientes (B2C)',
    number: '3.1.8',
    titulo: 'Perfil comportamental',
    text:
      'Foco nos clientes com características comportamentais similares (ex.: ocasiões de compra ou uso, índice de utilização, estágio de prontidão). Os produtos e serviços são projetados para atender as necessidades específicas do segmento. Exemplos: Smart Fit (usuários de outras academias), Tivoli (pacotes de hospedagem).',
    subtext:
      'Baseado em: Kotler, P. & Keller, K.L. (2012). Marketing management. 14th. Ed. Upper Saddle River: Prentice Hall.',
  },
  {
    id: '24',
    step: '3',
    nameStep: 'Segmentos de clientes (B2C)',
    number: '3.1.9',
    titulo: 'Perfil geográfico',
    text:
      'Foco nos clientes com características geográficas similares (ex.: região, porte da cidade ou região metropolita, densidade, área, clima, relevo). Os produtos e serviços são projetados para atender as necessidades específicas do segmento. Exemplos: varejo (localização de lojas e mix de produtos), canais de TV locais (ex.: CNN Brasil).',
    subtext:
      'Baseado em: Kotler, P. & Keller, K.L. (2012). Marketing management. 14th. Ed. Upper Saddle River: Prentice Hall.',
  },
  {
    id: '25',
    step: '3',
    nameStep: 'Segmentos de clientes (B2B)',
    number: '3.2.1',
    titulo: 'Aspectos demográficos',
    text:
      'Foco nos clientes empresariais com aspectos demográficos similares, tais como porte da empresa, setor de atuação, localização. Exemplos: Multinacionais, PMEs (pequenas e micro empresas), empresas de mineração, localização de software.',
    subtext:
      'Baseado em: Kotler, P. & Keller, K.L. (2012). Marketing management. 14th. Ed. Upper Saddle River: Prentice Hall.',
  },
  {
    id: '26',
    step: '3',
    nameStep: 'Segmentos de clientes (B2B)',
    number: '3.2.2',
    titulo: 'Aspectos operacionais',
    text:
      'Foco nos clientes empresariais com aspectos operacionais similares, tais como tecnologia, status de usuários e não-usuários, recursos dos clientes. Exemplos: Empresas que utilizam mainframe.',
    subtext:
      'Baseado em: Kotler, P. & Keller, K.L. (2012). Marketing management. 14th. Ed. Upper Saddle River: Prentice Hall.',
  },
  {
    id: '27',
    step: '3',
    nameStep: 'Segmentos de clientes (B2B)',
    number: '3.2.3',
    titulo: 'Abordagens de compra',
    text:
      'Foco nos clientes empresariais com abordagens de compra similares, tais como critérios de compras,  organização em relação a compras, estrutura de poder, natureza dos relacionamentos existentes, políticas gerais de compras. Exemplos: Empresas do setor público.',
    subtext:
      'Baseado em: Kotler, P. & Keller, K.L. (2012). Marketing management. 14th. Ed. Upper Saddle River: Prentice Hall.',
  },
  {
    id: '28',
    step: '3',
    nameStep: 'Segmentos de clientes (B2B)',
    number: '3.2.4',
    titulo: 'Fatores situacionais',
    text:
      'Foco nos clientes empresariais com fatores situacionais similares, tais como urgência, aplicação específica, tamanho do pedido. Exemplos: Segmentos da Danaher e da Fortive.',
    subtext:
      'Baseado em: Kotler, P. & Keller, K.L. (2012). Marketing management. 14th. Ed. Upper Saddle River: Prentice Hall.',
  },
  {
    id: '29',
    step: '3',
    nameStep: 'Segmentos de clientes (B2B)',
    number: '3.2.5',
    titulo: 'Características pessoais',
    text:
      'Foco nos clientes empresariais com características pessoais similares, tais como similaridade comprador-vendedor, atitudes em relação ao risco, fidelidade. Exemplos: Rede Agroservices.',
    subtext:
      'Baseado em: Kotler, P. & Keller, K.L. (2012). Marketing management. 14th. Ed. Upper Saddle River: Prentice Hall.',
  },
  {
    id: '30',
    step: '6',
    nameStep: 'Problemas a serem resolvidos (produtos)',
    number: '6.1.1',
    titulo: 'Problemas funcionais principais',
    text:
      'Consideram os problemas (jobs) associados às necessidades principais de uso ou consumo de um produto. Exemplos: desempenho funcional; confiabilidade do produto; conformidade do produto; durabilidade; facilidade de uso ou armazenamento; facilidade de manutenção; segurança ou risco de uso.',
    subtext: '',
  },
  {
    id: '31',
    step: '6',
    nameStep: 'Problemas a serem resolvidos (produtos)',
    number: '6.1.2',
    titulo: 'Problemas funcionais complementares',
    text:
      'Consideram os problemas (jobs) associados às necessidades complementares de uso ou consumo de um produto. Exemplos: características adicionais; acessórios; informação sobre o produto.',
    subtext: '',
  },
  {
    id: '32',
    step: '6',
    nameStep: 'Problemas a serem resolvidos (produtos)',
    number: '6.1.3',
    titulo: 'Problemas de conveniência de linha de produtos',
    text:
      'Consideram os problemas (jobs) associados às necessidades de linha de produtos oferecida na aquisição, uso ou consumo de um produto. Exemplos: variedade de produtos; variedade de opções ou itens opcionais; linha completa de produtos (one-stop-shop).',
    subtext: '',
  },
  {
    id: '33',
    step: '6',
    nameStep: 'Problemas a serem resolvidos (produtos)',
    number: '6.1.4',
    titulo: 'Problemas de conveniência de entrega',
    text:
      'Consideram os problemas (jobs) associados às necessidades de entrega, devolução e descarte de um produto. Exemplos: disponibilidade; velocidade de cotação e de emissão do pedido; velocidade de entrega; confiabilidade de entrega; informação sobre a entrega; conveniência na devolução ou descarte.',
    subtext: '',
  },
  {
    id: '34',
    step: '6',
    nameStep: 'Problemas a serem resolvidos (produtos)',
    number: '6.1.5',
    titulo: 'Problemas de conveniência de flexibilidade',
    text:
      'Consideram os problemas (jobs) associados às necessidades de flexibilidade oferecida na aquisição, uso ou consumo de um produto. Exemplos: flexibilidade de entrega, de mix ou de volume; flexibilidade de produto,  possibilidade de customização ou personalização.',
    subtext: '',
  },
  {
    id: '35',
    step: '6',
    nameStep: 'Problemas a serem resolvidos (produtos)',
    number: '6.1.6',
    titulo: 'Problemas de conveniência de atendimento',
    text:
      'Consideram os problemas (jobs) associados às necessidades de atendimento oferecido na aquisição, uso ou consumo e descarte de um produto. Exemplos: facilidade de busca; atendimento comercial; pós-atendimento ou suporte pós-venda (na instalação, manutenção, assistência técnica, descarte).',
    subtext: '',
  },
  {
    id: '36',
    step: '6',
    nameStep: 'Problemas a serem resolvidos (produtos)',
    number: '6.1.7',
    titulo: 'Problemas associados ao design do produto',
    text:
      'Consideram os problemas (jobs) associados à percepção de design na aquisição, uso ou consumo de um produto. Exemplos: harmonia das formas, cores, odores, texturas e sons do produto; beleza, estética de um produto e sua embalagem.',
    subtext: '',
  },
  {
    id: '37',
    step: '6',
    nameStep: 'Problemas a serem resolvidos (produtos)',
    number: '6.1.8',
    titulo: 'Problemas sociais, emocionais e aspiracionais',
    text:
      'Consideram os problemas (jobs) associados às necessidades sociais, emocionais e aspiracionais na aquisição, uso ou consumo de um produto. Exemplos: status pelo uso do produto; apego sentimental; necessidade de relacionamento; inclusão social, sentimento de pertencer a um grupo; realização pessoal; auto estima; bem-estar; conforto psicológico, acolhimento.',
    subtext: '',
  },
  {
    id: '38',
    step: '6',
    nameStep: 'Problemas a serem resolvidos (produtos)',
    number: '6.1.9',
    titulo: 'Problemas institucionais',
    text:
      'Consideram os problemas (jobs) associados às necessidades referentes à instituição que vende ou entrega um produto. Exemplos: imagem e reputação da empresa; confiança na organização; transparência e compliance (aderência legal, trabalhista, fiscal, ambiental); compromisso socioambiental; solidez financeira.',
    subtext: '',
  },
  {
    id: '39',
    step: '6',
    nameStep: 'Problemas a serem resolvidos (produtos)',
    number: '6.1.10',
    titulo: 'Problemas associados ao custo de aquisição',
    text:
      'Consideram os problemas (jobs) associados ao custo de aquisição de um produto. Exemplos: preço do produto; condições de pagamento; financiamento; depreciação.',
    subtext: '',
  },
  {
    id: '40',
    step: '6',
    nameStep: 'Problemas a serem resolvidos (produtos)',
    number: '6.1.11',
    titulo: 'Problemas associados ao custo de utilização',
    text:
      'Consideram os problemas (jobs) associados ao custo de utilização de um produto. Exemplos: preço dos consumíveis; custo de reparo e manutenção; custo do seguro; impostos associados ao uso ou propriedade do produto; custo de descarte.',
    subtext: '',
  },
  {
    id: '41',
    step: '6',
    nameStep: 'Problemas a serem resolvidos (serviços)',
    number: '6.2.1',
    titulo: 'Problemas funcionais principais',
    text:
      'Consideram os problemas (jobs) associados às necessidades principais de uso ou consumo de um serviço. Exemplos: elementos físicos associados aos serviços; qualidade dos insumos e recursos para realização do serviço; resolubilidade; consistência ou variabilidade do serviço; conformidade do serviço; segurança ou risco de uso.',
    subtext: '',
  },
  {
    id: '42',
    step: '6',
    nameStep: 'Problemas a serem resolvidos (serviços)',
    number: '6.2.2',
    titulo: 'Problemas funcionais complementares',
    text:
      'Consideram os problemas (jobs) associados às necessidades complementares de uso ou consumo de um serviço. Exemplos: características adicionais; complementos do serviço; informação sobre o serviço.',
    subtext: '',
  },
  {
    id: '43',
    step: '6',
    nameStep: 'Problemas a serem resolvidos (serviços)',
    number: '6.2.3',
    titulo: 'Problemas de conveniência de linha de serviços',
    text:
      'Consideram os problemas (jobs) associados às necessidades de linha de serviços oferecida na aquisição, uso ou consumo de um serviço. Exemplos: variedade de serviços; variedade de opções ou serviços opcionais; linha completa de serviços (one-stop-shop).',
    subtext: '',
  },
  {
    id: '44',
    step: '6',
    nameStep: 'Problemas a serem resolvidos (serviços)',
    number: '6.2.4',
    titulo: 'Problemas de conveniência de entrega',
    text:
      'Consideram os problemas (jobs) associados às necessidades de entrega de um serviço. Exemplos: disponibilidade ou tempo de fila (para agendamento); tempo e facilidade de acesso; velocidade de pré-atendimento, atendimento e pós-atendimento; pontualidade;  confiabilidade de entrega; conveniência na devolução ou descarte.',
    subtext: '',
  },
  {
    id: '45',
    step: '6',
    nameStep: 'Problemas a serem resolvidos (serviços)',
    number: '6.2.5',
    titulo: 'Problemas de conveniência de flexibilidade',
    text:
      'Consideram os problemas (jobs) associados às necessidades de flexibilidade oferecida no uso ou consumo de um serviço. Exemplos: flexibilidade de entrega, de mix ou de volume; personalização.',
    subtext: '',
  },
  {
    id: '46',
    step: '6',
    nameStep: 'Problemas a serem resolvidos (serviços)',
    number: '6.2.6',
    titulo: 'Problemas de conveniência de atendimento',
    text:
      'Consideram os problemas (jobs) associados às necessidades de atendimento oferecido no uso ou consumo de um serviço. Exemplos: facilidade de busca; empatia; cortesia no atendimento (hospitalidade); limpeza e conforto das instalações; atendimento comercial; pós-atendimento.',
    subtext: '',
  },
  {
    id: '47',
    step: '6',
    nameStep: 'Problemas a serem resolvidos (serviços)',
    number: '6.2.7',
    titulo: 'Problemas associados ao design dos recursos do serviço',
    text:
      'Consideram os problemas (jobs) associados à percepção de design nos recursos de um serviço. Exemplos: harmonia das formas, cores, odores, texturas e sons dos recursos (instalações e produtos utilizados no serviço); ambientação; aparência e apresentação das pessoas.',
    subtext: '',
  },
  {
    id: '48',
    step: '6',
    nameStep: 'Problemas a serem resolvidos (serviços)',
    number: '6.2.8',
    titulo: 'Problemas sociais, emocionais e aspiracionais',
    text:
      'Consideram os problemas (jobs) associados às necessidades sociais, emocionais e aspiracionais no uso ou consumo de um serviço. Exemplos: status pelo uso do serviço; apego sentimental; necessidade de relacionamento; inclusão social, sentimento de pertencer a um grupo; realização pessoal; auto estima; bem-estar; conforto psicológico, acolhimento. ',
    subtext: '',
  },
  {
    id: '49',
    step: '6',
    nameStep: 'Problemas a serem resolvidos (serviços)',
    number: '6.2.9',
    titulo: 'Problemas institucionais',
    text:
      'Consideram os problemas (jobs) associados às necessidades referentes à instituição que vende ou entrega um serviço. Exemplos: imagem e reputação da empresa; confiança na organização; transparência e compliance (aderência legal, trabalhista, fiscal, ambiental); compromisso socioambiental; solidez financeira.',
    subtext: '',
  },
  {
    id: '50',
    step: '6',
    nameStep: 'Problemas a serem resolvidos (serviços)',
    number: '6.2.10',
    titulo: 'Problemas associados ao custo de acesso',
    text:
      'Consideram os problemas (jobs) associados ao custo de acesso de um serviço. Exemplos: preço inicial (ex.: matrícula, inscrição); custo de mudança ou cancelamento.',
    subtext: '',
  },
  {
    id: '51',
    step: '6',
    nameStep: 'Problemas a serem resolvidos (serviços)',
    number: '6.2.11',
    titulo: 'Problemas associados ao custo de utilização',
    text:
      'Consideram os problemas (jobs) associados ao custo de utilização de um serviço. Exemplos: preço do serviço.',
    subtext: '',
  },
  {
    id: '52',
    step: '8',
    nameStep: 'Tipo do modelo de negócio',
    number: '8.1.1',
    titulo: 'Manufatura',
    text:
      'Considera o modelo de negócio “clássico” de manufatura: as empresas projetam, fabricam (com recursos próprios ou terceirizados) e vendem seus produtos. Exemplos: Apple, Natura, Nestlé, Nike, Petrobras, Riachuelo, Swatch, Tesla, Zara.',
    subtext: '',
  },
  {
    id: '53',
    step: '8',
    nameStep: 'Tipo do modelo de negócio',
    number: '8.1.2',
    titulo: 'Varejo e distribuição',
    text:
      'Considera o modelo de negócio no qual a empresa vende seus produtos por meio de canal de distribuição físico. Exemplos:  Saraiva, Livraria Cultura, Wal Mart, Carrefour, Pão de Açúcar, Magazine Luiza.',
    subtext: '',
  },
  {
    id: '54',
    step: '8',
    nameStep: 'Tipo do modelo de negócio',
    number: '8.1.3',
    titulo: 'Comércio eletrônico',
    text:
      'Considera o modelo de negócio no qual a empresa vende seus produtos por meio de canal de distribuição eletrônico. Exemplos: Amazon, Magalu (Magazine Luiza), Saraiva.com, Submarino.',
    subtext: '',
  },
  {
    id: '55',
    step: '8',
    nameStep: 'Tipo do modelo de negócio',
    number: '8.1.4',
    titulo: 'Serviços de utilidade pública',
    text:
      'Considera o modelo de negócio no qual a empresa entrega de serviços de utilidade pública. Exemplos: Enel, Comgás, Sabesp.',
    subtext: '',
  },
  {
    id: '56',
    step: '8',
    nameStep: 'Tipo do modelo de negócio',
    number: '8.1.5',
    titulo: 'Serviços intensivos em produtos',
    text:
      'Considera o modelo de negócio no qual a empresa entrega serviços que processam (e transportam) principalmente insumos físicos. Esses serviços podem apresentar maior ou menor intensidade em conhecimento e/ou tecnologia. Exemplos: Starbucks, Localiza, Car2Go, Zipcar, Netflix (DVD por correio).',
    subtext: '',
  },
  {
    id: '57',
    step: '8',
    nameStep: 'Tipo do modelo de negócio',
    number: '8.1.6',
    titulo: 'Serviços intensivos em pessoas',
    text:
      'Considera o modelo de negócio no qual a empresa entrega serviços que processam (e transportam) principalmente pessoas. Esses serviços podem apresentar maior ou menor intensidade em conhecimento e/ou tecnologia. Exemplos: Ryanair, Aravind, AC Camargo, Dasa, Grupo Fleury.',
    subtext: '',
  },
  {
    id: '58',
    step: '8',
    nameStep: 'Tipo do modelo de negócio',
    number: '8.1.7',
    titulo: 'Serviços intensivos em informação',
    text:
      'Considera o modelo de negócio no qual a empresa entrega serviços que processam (e transportam) principalmente informações. Esses serviços podem apresentar maior ou menor intensidade em conhecimento e/ou tecnologia. Exemplos: Bradesco, Itaú, Nubank, Fintechs (em geral), 23andMe, Netflix (streaming).',
    subtext: '',
  },
  {
    id: '59',
    step: '8',
    nameStep: 'Tipo do modelo de negócio',
    number: '8.1.8',
    titulo: 'Rede social',
    text:
      'Considera o modelo de negócio no qual a empresa realiza conexões entre pessoas ou ponto-a-ponto, e procura monetizar seu negócio decorrente dessas conexões.Exemplos: Facebook, LinkedIn, PatientslikeMe.',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 252-256. (#37 Peer to peer).',
  },
  {
    id: '60',
    step: '8',
    nameStep: 'Tipo do modelo de negócio',
    number: '8.1.9',
    titulo: 'Mercado multilateral',
    text:
      'Considera o modelo de negócio no qual a empresa realiza conexões entre dois ou mais mercados, e procura monetizar seu negócio decorrente dessas conexões. Exemplos: Uber, Airbnb, Etsy.',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 321-325. (# 52 Two-sided market).',
  },
  {
    id: '61',
    step: '8',
    nameStep: 'Tipo do modelo de negócio',
    number: '8.1.10',
    titulo: 'Ecossistema',
    text:
      'Considera o modelo de negócio no qual a empresa realiza conexões entre vários atores (players) e governa as ações em um determinado ecossistema. A monetização do negócio é decorrente dessas conexões e respectiva governança. Exemplos: Apple, Google, Microsoft, BM&F Bovespa.',
    subtext: '',
  },
  {
    id: '62',
    step: '9',
    nameStep: 'Especificação da solução',
    number: '9.1.1',
    titulo: 'One-stop-shop',
    text:
      'Considera a oferta de soluções do tipo "one-stop-shop". Assim, a empresa oferece uma solução abrangente (formada por um conjunto de produtos e serviços) que atende as necessidades dos clientes em um único lugar. Exemplos: Amazon, Alibaba, shopping centers, Americas Medical City.',
    subtext: '',
  },
  {
    id: '63',
    step: '9',
    nameStep: 'Especificação da solução',
    number: '9.1.2',
    titulo: 'Cauda longa',
    text:
      'Considera a oferta de soluções do tipo "cauda longa". Assim, a empresa  oferece uma grande variedade de itens sendo que cada um demanda pequenas quantidades. Exemplos:  eBay, YouTube, Etsy, Elo7, Netflix (DVD por correio), Lulu (editora on-line), Cemitério dos Azulejos.',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 212-215. (#28 Long tail).',
  },
  {
    id: '64',
    step: '9',
    nameStep: 'Especificação da solução',
    number: '9.1.3',
    titulo: 'Presença',
    text:
      'Considera a oferta de uma elevada capilaridade por meio de instalações ou pontos de contato físicos. Exemplos: Bradesco, Correios, Casa Lotérica, McDonalds.',
    subtext: '',
  },
  {
    id: '65',
    step: '9',
    nameStep: 'Especificação da solução',
    number: '9.1.4',
    titulo: 'Delivery',
    text:
      'Considera a entrega de soluções (produtos e serviços) no local de consumo ou uso. Exemplos: ifood, McDonald´s (McDelivery), Netflix (DVD por correio), Dr. Vem, Tesco (loja virtual), Singu (delivery de serviços de beleza e bem-estar).',
    subtext: '',
  },
  {
    id: '66',
    step: '9',
    nameStep: 'Especificação da solução',
    number: '9.1.5',
    titulo: 'Disponibilidade garantida',
    text:
      'Considera a oferta de um amplo acesso ou a uma alta disponibilidade de soluções (produtos e serviços) aos usuários. Exemplos:  Schindler (manutenção de elevadores), serviços 24 horas, operações de varejo 24 horas, pronto atendimento em serviços de saúde.',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 174-178. (# 20 Guaranteed availability).',
  },
  {
    id: '67',
    step: '9',
    nameStep: 'Especificação da solução',
    number: '9.1.6',
    titulo: 'Customização em massa',
    text:
      'Considera a oferta, em volumes relativamente elevados, de soluções que atendem as necessidades dos clientes. Isso geralmente é realizado por uma combinação de opções. Exemplos: Dell (modelo built-to-order), Subway, M&M (personalizado).',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 221-225. (#30 Mass customisation).',
  },
  {
    id: '68',
    step: '9',
    nameStep: 'Especificação da solução',
    number: '9.1.7',
    titulo: 'Personalização',
    text:
      'Considera a oferta de soluções personalizadas aos clientes. Exemplos: farmácia de manipulação, roupa sob medida, Hotel Emiliano.',
    subtext: '',
  },
  {
    id: '69',
    step: '9',
    nameStep: 'Especificação da solução',
    number: '9.1.8',
    titulo: 'Valor social',
    text:
      'Considera a oferta de soluções que entregam valor social aos usuários. Exemplos:  Harley Davidson, Facebook, Tinder, LinkedIn.',
    subtext: '',
  },
  {
    id: '70',
    step: '9',
    nameStep: 'Especificação da solução',
    number: '9.1.9',
    titulo: 'Valor emocional',
    text:
      'Considera a oferta de soluções que entregam valor emocional aos usuários. Exemplos: Valisère, Swatch.',
    subtext: '',
  },
  {
    id: '71',
    step: '9',
    nameStep: 'Especificação da solução',
    number: '9.1.10',
    titulo: 'Bem-estar',
    text:
      'Considera a oferta de soluções que entregam bem-estar aos usuários. Exemplos: Natura, Nestlé, Smart Fit, Gympass, Tech.fit.',
    subtext: '',
  },
  {
    id: '72',
    step: '11',
    nameStep: 'Posicionamento competitivo',
    number: '11.1.1',
    titulo: 'Baixo custo (frugal)',
    text:
      'Considera a oferta de soluções de baixo custo. Assim, a empresa procura remover os atributos não essenciais do produto ou serviço, e promover elevados padrões de eficiência operacional (ex.: economia de escala, redução de complexidades operacionais). Exemplos: Ford (modelo T), atacarejo, Smart Fit, Southwest, Ryanair, Walmart.',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 226-229. (# 31 No frills).',
  },
  {
    id: '73',
    step: '11',
    nameStep: 'Posicionamento competitivo',
    number: '11.1.2',
    titulo: 'Melhores produtos',
    text:
      'Considera a oferta de soluções que apresentam os melhores produtos e serviços. Assim, a empresa objetiva a liderança ou diferenciação de produtos e serviços. Para tanto, as empresas geralmente se destacam em um conjunto específico de atributos. Exemplos:  segurança (Volvo), desempenho funcional (Apple, Intel, Porsche), estratégia (McKinsey).',
    subtext:
      'Baseado em: Treacy, M. & Wiersema, F. (1995). The discipline of market leaders. New York: Perseus Books.',
  },
  {
    id: '74',
    step: '11',
    nameStep: 'Posicionamento competitivo',
    number: '11.1.3',
    titulo: 'Fidelidade forçada (lock-in)',
    text:
      'Considera a oferta de soluções que promovam a fidelidade dos clientes. Isso ocorre principalmente por meio de incentivos para sua permanência - ou, na prática, por meio de desincentivos para mudanças para outras soluções, tais como padrões proprietários e altos custos de mudanças. Exemplos: Apple, Nespresso, Lego, SAP.',
    subtext:
      'Baseado em: Treacy, M. & Wiersema, F. (1995). The discipline of market leaders. New York: Perseus Books. Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 207-211. (# 27 Lock-in).',
  },
  {
    id: '75',
    step: '11',
    nameStep: 'Posicionamento competitivo',
    number: '11.1.4',
    titulo: 'Provedor de soluções',
    text:
      'Considera a oferta de soluções para atender as necessidades específicas do cliente. Para tanto, a empresa compreende as necessidades do cliente (ex.: pré-venda), entrega uma solução para atender essas necessidades e oferece atividades de monitoramento após a entrega (ex.: pós-venda). Exemplos: Accenture, IBM, TetraPak.',
    subtext:
      'Baseado em: Treacy, M. & Wiersema, F. (1995). The discipline of market leaders. New York: Perseus Books. Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 299-303. (# 47 Solution provider).',
  },
  {
    id: '76',
    step: '11',
    nameStep: 'Posicionamento competitivo',
    number: '11.1.5',
    titulo: 'Experiência do cliente',
    text:
      'Considera a incorporação de benefícios intangíveis - particularmente emocionais e sociais – na entrega da solução. Assim, a empresa também se destaca pela experiência do cliente (conjunto de benefícios funcionais, de conveniência e intangíveis). Exemplos:  Disney, Emiliano, Grupo Fleury (marca Fleury), Starbucks, Tivoli.',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 146-150. (# 14 Experience selling).',
  },
  {
    id: '77',
    step: '11',
    nameStep: 'Posicionamento competitivo',
    number: '11.1.6',
    titulo: 'Boa relação custo-benefício',
    text:
      'Considera a oferta de soluções com posicionamento intermediário. Assim, não se posicionam nem como os melhores, nem como os mais baratos. Tipicamente, as mensagens são do tipo: “boa relação custo-benefício”, “cabe no bolso”. Exemplos: Itaú, Cacau Show.',
    subtext: '',
  },
  {
    id: '78',
    step: '11',
    nameStep: 'Posicionamento competitivo',
    number: '11.1.7',
    titulo: 'Multimarca',
    text:
      'Considera a oferta de soluções para atender as necessidades de diferentes segmentos de clientes. Tipicamente, essas soluções são oferecidas por diversas marcas de um mesmo grupo. Exemplos: Accor, Marriot, Bradesco, Itaú, Grupo Swatch, Dasa, Grupo Fleury.',
    subtext: '',
  },
  {
    id: '79',
    step: '11',
    nameStep: 'Posicionamento competitivo',
    number: '11.1.9',
    titulo: 'Robin Hood',
    text:
      'Considera a oferta de soluções aos “ricos” por um preço superior do que os “pobres” pagam. Assim, os “ricos” subsidiam os “pobres”. Dessa forma, os resultados financeiros da empresa estão predominantemente associados ao atendimento dos “ricos”. Exemplos: Aravind, AC Camargo Cancer Center, Geekie, TOMS.',
    subtext: '',
  },
  {
    id: '80',
    step: '12',
    nameStep: 'Modelo de geração de receita',
    number: '12.1.1',
    titulo: 'Venda de produto',
    text:
      'Considera o modelo clássico de venda de um produto. Assim, a posse do produto ou ativo se transfere do vendedor para o comprador. Exemplos: venda de qualquer produto.',
    subtext: '',
  },
  {
    id: '81',
    step: '12',
    nameStep: 'Modelo de geração de receita',
    number: '12.1.2',
    titulo: 'Aluguel',
    text:
      'Considera o aluguel e leasing de um produto ou ativo (ex.: equipamento, automóvel, imóvel). Assim, o cliente paga pelo direito de uso temporário ou prazo determinado de um produto ou ativo. Exemplos:  locação e leasing de veículos (Avis, Hertz, Localiza), roupas e acessórios (Dress & Go), equipamentos médicos (GE, Siemens).',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 265-268. (# 40 Rent instead of buy).',
  },
  {
    id: '82',
    step: '12',
    nameStep: 'Modelo de geração de receita',
    number: '12.1.3',
    titulo: 'Pagamento por pacote',
    text:
      'Considera o pagamento de um preço fixo para o consumo ilimitado (ou dentro de limites máximos acordados) de uma solução. Nesse caso, a solução contempla um pacote, ou seja, um conjunto de produtos e serviços. Exemplos: buffet (“all you can eat”), hotéis e resorts do tipo “all inclusive”, pacote de procedimentos médicos.',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 151-154. (# 15 Flat rate).',
  },
  {
    id: '83',
    step: '12',
    nameStep: 'Modelo de geração de receita',
    number: '12.1.4',
    titulo: 'Pagamento por serviço',
    text:
      'Considera o pagamento pelo uso ou consumo de um produto ou serviço. Assim, o consumo ou uso é medido, e o cliente paga especificamente pela quantidade consumida. Exemplos: pagamento por procedimento na saúde (fee for service), por conteúdo (pay per view), por compartilhamento de automóveis (Car2Go, Zipcar, Zazcar), bicicletas e patinetes (Yellow).',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 244-247. (# 35 Pay per use).',
  },
  {
    id: '84',
    step: '12',
    nameStep: 'Modelo de geração de receita',
    number: '12.1.5',
    titulo: 'Pagamento por subscrição',
    text:
      'Considera o pagamento pelo acesso, consumo ou uso regularmente de uma solução. Assim, a subscrição (ou assinatura) possibilita que o cliente receba ou tenha acesso regularmente a um conjunto de produtos e serviços. Exemplos:  assinatura de conteúdo (Amazon Prime, Netflix), música (Spotify), academias de ginástica (Gympass, Smart Fit), planos de saúde, assinaturas de diferentes produtos.',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 304-307. (# 48 Subscription).',
  },
  {
    id: '85',
    step: '12',
    nameStep: 'Modelo de geração de receita',
    number: '12.1.6',
    titulo: 'Pagamento per capita',
    text:
      'Considera a pagamento de um preço fixo por pessoa para acesso, uso ou consumo de soluções oferecidas a um conjunto de pessoas. Exemplos: pagamento per capita na saúde (capitation), festas de casamento, aniversários e formatura (pagamento por pessoa).',
    subtext: '',
  },
  {
    id: '86',
    step: '12',
    nameStep: 'Modelo de geração de receita',
    number: '12.1.7',
    titulo: 'Pagamento por atributo',
    text:
      'Considera o pagamento adicional (add-on) por produtos e serviços complementares ou acessórios de uma solução. Assim, os atributos adicionados aos produtos ou serviços principais geram uma receita adicional (em geral, proporcionalmente maior) à empresa. Exemplos: acessórios (automóveis), módulos adicionais (SAP), serviços adicionais (Ryanair), bebidas e sobremesas em restaurantes.',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 83-88. (# 01 Add-on).',
  },
  {
    id: '87',
    step: '12',
    nameStep: 'Modelo de geração de receita',
    number: '12.1.8',
    titulo: 'Freemium',
    text:
      'Considera um pagamento duplo: acesso gratuito (free) para versões básicas e acesso pago para versões avançadas (premium) de uma solução. A versão gratuita geralmente serve para aquisição de clientes. Assim, o principal desafio da empresa consiste em converter os usuários gratuitos em pagos. Exemplos:  Spotify, LinkedIn, Dropbox, Coursera, Duolingo.',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 165-168. (# 18 Freemium).',
  },
  {
    id: '88',
    step: '12',
    nameStep: 'Modelo de geração de receita',
    number: '12.1.9',
    titulo: 'Receita indireta',
    text:
      'Considera a oferta de soluções (geralmente) gratuitas para os usuários, e a cobrança por fontes alternativas, principalmente de serviços para acessar esses usuários. Assim, a receita é obtida indiretamente, particularmente por quem valoriza o acesso aos usuários. Exemplos: anunciantes do Google, Facebook, TV aberta.',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 179-182. (# 21 Hidden revenue).',
  },
  {
    id: '89',
    step: '12',
    nameStep: 'Modelo de geração de receita',
    number: '12.1.10',
    titulo: 'Aparelho e lâmina',
    text:
      'Considera um pagamento duplo: o produto principal é oferecido por um preço relativamente baixo e os produtos complementares são vendidos por um preço relativamente alto. Também pode ocorrer uma inversão, na qual o produto principal é relativamente caro (ex.: Apple iPod), pois permite acessar complementos relativamente baratos. Exemplos: Gillette, HP, Nespresso, Amazon Kindle. ',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 261-264. (# 39 Razor and blade).',
  },
  {
    id: '90',
    step: '12',
    nameStep: 'Modelo de geração de receita',
    number: '12.1.11',
    titulo: 'Oferta pelo cliente',
    text:
      'Considera que, em última instância, o preço é definido pelo cliente. Este oferece (ou define) o quanto ele está disposto a pagar por um produto ou serviço. Exemplos: Humble Bundle, algumas modalidades de atendimento social, artistas de rua.',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 248-251. (# 36 Pay what you want).',
  },
  {
    id: '91',
    step: '12',
    nameStep: 'Modelo de geração de receita',
    number: '12.1.12',
    titulo: 'Leilão',
    text:
      'Considera que o preço é definido por meio de um processo participativo. No leilão tradicional, a empresa (ou pessoa) oferece um produto ou serviço e vende para quem fizer a melhor oferta (maior preço). No leilão reverso, a empresa (ou pessoa) informa o que está disposta a comprar, e escolhe quem oferecer a melhor oferta (menor preço). Exemplos: Google (leilão de anúncios), eBay, Priceline, Veiling Holambra (leilão de flores).',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 99-103. (# 04 Auction).',
  },
  {
    id: '92',
    step: '12',
    nameStep: 'Modelo de geração de receita',
    number: '12.1.13',
    titulo: 'Oferta e demanda',
    text:
      'Considera que o preço segue as leis da oferta e demanda. Assim, aumentos da demanda e/ou reduções da oferta fazem com que os preços subam. Ao contrário, reduções da demanda e/ou aumentos da oferta fazem com que os preços caiam. Exemplos: preço das commodities, Uber (Surge pricing – ou horário de pico), Lyft (Prime time – ou horário nobre).',
    subtext: '',
  },
  {
    id: '93',
    step: '12',
    nameStep: 'Modelo de geração de receita',
    number: '12.1.14',
    titulo: 'Gestão do rendimento',
    text:
      'Considera os casos em que a empresa adota um modelo de gestão de preços e capacidade (oferta de serviços) em função da demanda. Assim, a empresa altera os preços e a disponibilidade de capacidade visando maximizar seus rendimentos. Exemplos: Sabre, preços de passagens aéreas, preços de hotéis.',
    subtext: '',
  },
  {
    id: '94',
    step: '12',
    nameStep: 'Modelo de geração de receita',
    number: '12.1.15',
    titulo: 'Permuta',
    text:
      'Considera que o preço é definido por meio de uma permuta (escambo ou troca direta). Assim, ocorre uma transação (ou contrato) em que cada uma das partes entrega um bem ou presta um serviço para receber da outra parte um bem ou serviço na  forma de crédito, sem que um dos bens seja moeda. Exemplos: Procter & Gamble (patrocínio vs. espaço publicitário), insumos agrícolas vs. safra agrícola. ',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 104-108. (# 05 Barter).',
  },
  {
    id: '95',
    step: '12',
    nameStep: 'Modelo de geração de receita',
    number: '12.1.16',
    titulo: 'Afiliação',
    text:
      'Considera o modelo em que uma empresa se afilia a outra para comercializar seus produtos e serviços. O apoio à comercialização, em geral, é monetizado na forma de uma comissão por venda ou geração de oportunidade (lead). Exemplos: corretores (imóveis, seguros e planos de saúde), Amazon, Airbnb, Uber.',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 89-93. (# 02 Affiliation).',
  },
  {
    id: '96',
    step: '12',
    nameStep: 'Modelo de geração de receita',
    number: '12.1.17',
    titulo: 'Propaganda',
    text:
      'Considera o modelo em que uma empresa se afilia a outra para divulgar  seus produtos e serviços. O apoio à divulgação é monetizado na forma de propaganda e publicidade. Exemplos: Google, Facebook (e Instagram), Pinterest.',
    subtext: '',
  },
  {
    id: '97',
    step: '12',
    nameStep: 'Modelo de geração de receita',
    number: '12.1.18',
    titulo: 'Licenciamento',
    text:
      'Considera o modelo em que uma empresa comercializa propriedade intelectual. Esta pode ser propriedade industrial e direito autoral. A propriedade industrial diz respeito a itens como patentes, marcas e desenhos industriais. O direito autoral considera obras literárias, intelectuais e artísticas. Exemplos: IBM, licenciamento de medicamentos, royalties cobrados em franquias.  ',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 202-206. (# 26 Licensing).',
  },
  {
    id: '98',
    step: '12',
    nameStep: 'Modelo de geração de receita',
    number: '12.1.19',
    titulo: 'Compartilhamento de receita',
    text:
      'Considera o modelo em que uma empresa atua em conjunto com outra empresa, sendo que ambas compartilham os resultados (receitas, lucros e/ou riscos). Assim, ocorre uma simbiose: uma associação (a longo prazo) entre duas (ou mais) empresas de forma que a relação seja benéfica para as envolvidas. Exemplos: Blockbuster (estúdios),  Embraer (ERJ-145), Apple e Google (desenvolvedores de aplicativos).',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 269-273. (# 41 Revenue sharing).',
  },
  {
    id: '99',
    step: '12',
    nameStep: 'Modelo de geração de receita',
    number: '12.1.20',
    titulo: 'Remuneração por desempenho',
    text:
      'Considera o modelo em que o preço de uma solução está associado ao seu desempenho. Assim, o valor efetivamente pago é baseado em resultados (receitas, custos, lucro, efetividade). Exemplo: pagamento por desempenho na saúde (pay-for-performance), Rolls Royce (contrato Power by the hour), Johnson & Johnson (Velcade), remuneração de consultorias.',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 257-260. (# 38 Performance-based contracting).',
  },
  {
    id: '100',
    step: '12',
    nameStep: 'Modelo de geração de receita',
    number: '12.1.21',
    titulo: 'Financiamento coletivo de produtos e serviços',
    text:
      'Considera o modelo em que a receita é gerada por meio do financiamento coletivo do desenvolvimento e entrega de uma solução. Assim, a obtenção de capital é obtida por meio de múltiplos atores (pessoas físicas e jurídicas), que recebem a contrapartida da entrega de um produto e serviço a posteriori. Exemplos: Filippo Loretti (relógios), Bookstart, Kickante, Catarse.',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 117-120. (# 08 Crowdfunding).',
  },
  {
    id: '101',
    step: '12',
    nameStep: 'Modelo de geração de receita',
    number: '12.1.22',
    titulo: 'Doações',
    text:
      'Considera o modelo em que a receita é gerada por meio de doações. Assim, a obtenção de capital é obtida por meio de múltiplos atores (pessoas físicas e jurídicas), que objetivam colaborar para a manutenção sustentável das atividades de interesse social das organizações que recebem as doações. Exemplos: fundos de endowment,  AACD (Teleton), Mozilla (Firefox), Wikipedia.',
    subtext: '',
  },
  {
    id: '102',
    step: '15',
    nameStep: 'Modelo econômico',
    number: '15.1.1',
    titulo: 'Excelência operacional',
    text:
      'Considera que a geração de valor é direcionada, em grande parte, pela excelência operacional. Assim, a empresa reduz os custos de produtos vendidos, os custos dos serviços e as despesas operacionais. Exemplos: Toyota, Hyundai-Kia, Foxconn, Narayana Hrudayalaya Hospitals.',
    subtext: '',
  },
  {
    id: '103',
    step: '15',
    nameStep: 'Modelo econômico',
    number: '15.1.2',
    titulo: 'Eficiência no uso dos ativos',
    text:
      'Considera que a geração de valor é direcionada, em grande parte, pela eficiência no uso dos ativos. Assim, a empresa gerencia efetivamente seus ativos fixos e de longo prazo. Exemplos: Car2Go, Zipcar, Smart Fit, WeWork.',
    subtext: '',
  },
  {
    id: '104',
    step: '15',
    nameStep: 'Modelo econômico',
    number: '15.1.3',
    titulo: 'Eficiência no fluxo de caixa',
    text:
      'Considera que a geração de valor é direcionada, em grande parte, pela eficiência no fluxo de caixa. Assim, a empresa reduz as necessidades de capital de giro (por vezes, o capital de giro é negativo) em função dos ciclos de conversão de estoques, contas a receber e contas a pagar. Exemplos: Amazon, Dell (na fase do modelo built-to-order), Time for Fun, Zara.',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 109-112. (# 06 Cash machine).',
  },
  {
    id: '105',
    step: '15',
    nameStep: 'Modelo econômico',
    number: '15.1.4',
    titulo: 'Eficiência financeira',
    text:
      'Considera que a geração de valor é direcionada, em grande parte, pela eficiência financeira. Assim, a empresa gerencia efetivamente seus custo de capital, ativos não-operacionais e impostos. Exemplos: inversão da matriz corporativa (ex.: Burger King), divisões financeiras de empresas (ex.: Riachuelo e Midway).',
    subtext: '',
  },
  {
    id: '106',
    step: '15',
    nameStep: 'Modelo econômico',
    number: '15.1.5',
    titulo: 'Investimento colaborativo',
    text:
      'Considera o modelo em que há um investimento coletivo para o desenvolvimento da empresa. Assim, a obtenção de capital é realizada por meio de múltiplos atores (pessoas físicas e jurídicas), que recebem a contrapartida de benefícios a posteriori (ex.: debêntures conversíveis, participação acionária). Exemplos: Eqseed, Startmeup.',
    subtext: '',
  },
  {
    id: '107',
    step: '15',
    nameStep: 'Modelo econômico',
    number: '15.1.6',
    titulo: 'Empréstimo coletivo',
    text:
      'Considera o modelo em que há um empréstimo coletivo para a empresa. Assim, a obtenção de capital, na forma de empréstimo, é realizada por meio de múltiplos atores (pessoas físicas e jurídicas). Exemplos: Lending Club, Geru, Biva, Nexoos, Virtus.',
    subtext: '',
  },
  {
    id: '108',
    step: '16',
    nameStep: 'Modelo de operações',
    number: '16.1.1',
    titulo: 'Oferta baseada nos recursos',
    text:
      'Considera o uso das competências (em geral) ou dos recursos (em particular) para resolver problemas de pessoas ou outras empresas (e não somente internamente à organização). Assim, está associada à abordagem da visão baseada nos recursos (ou RBV – Resource-based view). Exemplos: Amazon Web Services, Porsche (Engineering e Consulting), Festo, Hospital Albert Einstein (consultoria, segunda opinião).',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 216-220. (# 29 Make more of it).',
  },
  {
    id: '109',
    step: '16',
    nameStep: 'Modelo de operações',
    number: '16.1.2',
    titulo: 'Outsourcing de processos (BPO)',
    text:
      'Considera o uso dos processos para resolver problemas de pessoas ou outras empresas (e não somente internamente à organização). Assim, está associado à oferta de terceirização de processos. Exemplos: Accenture (outsourcing de processos), IBM (outsourcing de tecnologia da informação), Sodexo, serviços de contabilidade, jurídicos e de call center.',
    subtext: '',
  },
  {
    id: '110',
    step: '16',
    nameStep: 'Modelo de operações',
    number: '16.1.3',
    titulo: 'Servitização',
    text:
      'Considera a agregação de valor aos produtos de uma empresa por meio da oferta de serviços associados a eles, ou mesmo na entrega dos produtos na forma de serviços. Assim, está relacionada ao conceito de sistema produto-serviço (PSS – product-service system).   Exemplos: IBM, Xerox, Rolls Royce (turbinas).',
    subtext: '',
  },
  {
    id: '111',
    step: '16',
    nameStep: 'Modelo de operações',
    number: '16.1.4',
    titulo: 'Industrialização de serviços',
    text:
      'Considera a agregação de valor aos serviços por meio da automatização de atividades, incorporação tecnológica ou mesmo transformação de serviços em produtos. Assim, está relacionada ao conceito de sistema produto-serviço (PSS – product-service system).   Exemplos: análises clínicas, bancos (ex.: ATM), processos de atendimento ao cliente (ex.: chat bot).',
    subtext: '',
  },
  {
    id: '112',
    step: '16',
    nameStep: 'Modelo de operações',
    number: '16.1.5',
    titulo: 'Digitalização',
    text:
      'Considera a agregação de valor por meio da digitalização. Nesta, a imagem ou sinal analógico é transformado em código digital. Assim, os produtos físicos podem ser convertidos em mídias digitais, bem como sua entrega pode ocorrer de forma digital. Exemplos: Netflix (streaming), Napster, Spotify, conteúdo digital (jornais, revistas, Ebooks), radiologia digital.',
    subtext: '',
  },
  {
    id: '113',
    step: '16',
    nameStep: 'Modelo de operações',
    number: '16.1.6',
    titulo: 'Computação em nuvem',
    text:
      'Considera a agregação de valor por meio da computação em nuvem. Nesta, os recursos computacionais são disponibilizados pela Internet (“nuvem”) e são acessados sob demanda. Geralmente está associada à virtualização, uma tecnologia que separa as funções do hardware. Exemplos: Amazon Web Services, IBM Cloud, Microsoft Azure, Salesforce.',
    subtext: '',
  },
  {
    id: '114',
    step: '16',
    nameStep: 'Modelo de operações',
    number: '16.1.7',
    titulo: 'Impressão 3D',
    text:
      'Considera a agregação de valor por meio de impressão 3D.  Esta é uma forma de tecnologia de fabricação aditiva, no qual um modelo tridimensional é criado por meio da sobreposição de várias camadas de materiais.   Exemplos: diversos produtos resultantes de prototipação rápida, Adidas (tênis com sola feita em impressora 3D), Pés Sem Dor (impressão de palmilhas em 3D).',
    subtext: '',
  },
  {
    id: '115',
    step: '16',
    nameStep: 'Modelo de operações',
    number: '16.1.8',
    titulo: 'Internet das Coisas (IoT)',
    text:
      'Considera a agregação de valor por meio da utilização da  Internet das Coisas (IoT – Internet of Things). Nesta, ocorre uma interconexão digital de objetos com a Internet. Exemplos: Sem Parar; dispositivos inteligentes da Apple, Amazon e Google; tecnologias vestíveis (wearables).',
    subtext: '',
  },
  {
    id: '116',
    step: '16',
    nameStep: 'Modelo de operações',
    number: '16.1.9',
    titulo: 'Automação do trabalho intensivo em conhecimento',
    text:
      'Considera a agregação de valor por meio da automação do trabalho intensivo em conhecimento. Nesta, os recursos computacionais são utilizados para automatizar funções cognitivas (em geral), analíticas e decisórias (em particular) dos seres humanos. Para tanto, são utilizadas abordagens como inteligência artificial (AI), inteligência analítica (analytics) e automação com software robôs (RPA). Exemplos: IBM (Watson), Google (quantum computing), Bradesco (BIA).',
    subtext: '',
  },
  {
    id: '117',
    step: '16',
    nameStep: 'Modelo de operações',
    number: '16.2.1',
    titulo: 'Franquia',
    text:
      'Considera a adoção da franquia como direcionador estratégico de eficiência operacional. A agregação de valor ocorre principalmente por meio de economia de escala e escopo. Assim, o franqueador cede ao franqueado o direito de explorar seu modelo de negócio, mediante condições estabelecidas entre ambos. Exemplos: Yázigi, McDonald’s, Subway, O Boticário, Smart Fit, Cacau Show.',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 160-164. (# 17 Franchising).',
  },
  {
    id: '118',
    step: '16',
    nameStep: 'Modelo de operações',
    number: '16.2.2',
    titulo: 'Cadeia integrada',
    text:
      'Considera a adoção da cadeia integrada como direcionador estratégico de eficiência operacional. A agregação de valor ocorre principalmente por meio de economia de escala e escopo. A empresa adota uma estratégia de integração vertical. Assim, controla grande parte de sua cadeia de suprimentos. Exemplos: Zara, Riachuelo, Bunge, Suzano, Petrobrás.',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 188-192. (# 23 Integrator).',
  },
  {
    id: '119',
    step: '16',
    nameStep: 'Modelo de operações',
    number: '16.2.3',
    titulo: 'Loja dentro de loja',
    text:
      'Considera a adoção da loja dentro de loja como direcionador estratégico de eficiência operacional. A agregação de valor ocorre principalmente por meio de economia de escala e escopo. Assim, uma empresa estabelece uma loja (ou mesmo uma operação) dentro da loja (ou outro espaço) de outra empresa. Exemplos: Saraiva e Starbucks, Extra e Ri Happy, consórcio modular na indústria automobilística.',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 294-298. (# 46 Shop in shop).',
  },
  {
    id: '120',
    step: '16',
    nameStep: 'Modelo de operações',
    number: '16.2.4',
    titulo: 'Supermercado',
    text:
      'Considera a adoção do modelo de supermercado como direcionador estratégico de eficiência operacional. A agregação de valor ocorre principalmente por meio de economia de escala e escopo. Assim, uma ampla variedade de itens (produtos ou serviços) de diferentes fornecedores ou lojas é oferecida em um único local. Exemplos: Pão de Açúcar, Carrefour, Wal Mart, Amazon, Alibaba, shopping centers.',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 308-311. (# 49 Supermarket).',
  },
  {
    id: '121',
    step: '16',
    nameStep: 'Modelo de operações',
    number: '16.2.5',
    titulo: 'Venda cruzada',
    text:
      'Considera a adoção da venda cruzada como direcionador estratégico de eficiência operacional. A agregação de valor ocorre principalmente por meio de economia de escala e escopo. Assim, itens complementares (produtos ou serviços) de diferentes fornecedores ou lojas são oferecidos em um único local.  Exemplos: Amazon, Saraiva.com, IKEA, Tok & Stok, Leroy Merlin, Posto Ipiranga.',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 113-116. (# 7 Cross selling).',
  },
  {
    id: '122',
    step: '16',
    nameStep: 'Modelo de operações',
    number: '16.2.6',
    titulo: 'Fornecedor especializado',
    text:
      'Considera a adoção do modelo de fornecedor especializado como direcionador estratégico de eficiência operacional. A agregação de valor oocrre principalmente por meio de curva de experiência. Assim, o fornecedor se concentra em uma ou poucas atividades de uma cadeia de valor, e se especializa nessa(s) atividade(s). Exemplos: DHL, PayPal, Loggi, Foxconn, AC Camargo Cancer Center, Aravind, Narayana, Hospital do Rim e Hipertensão.',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 193-196. (# 24 Layer player).',
  },
  {
    id: '123',
    step: '16',
    nameStep: 'Modelo de operações',
    number: '16.2.7',
    titulo: 'Operações focadas',
    text:
      'Considera a adoção do modelo de operações focadas como direcionador estratégico de eficiência operacional. A agregação de valor ocorre principalmente por meio de curva de experiência. Assim, a empresa oferece uma ampla variedade de itens (produtos ou serviços) por meio de unidades especializadas. Exemplos: Hospital das Clínicas da FMUSP.',
    subtext: '',
  },
  {
    id: '124',
    step: '16',
    nameStep: 'Modelo de operações',
    number: '16.2.8',
    titulo: 'Operações responsivas',
    text:
      'Considera a adoção do modelo de operações responsivas como direcionador estratégico de eficiência operacional. A agregação de valor ocorre principalmente por meio da adequada utilização dos recursos. Assim, as operações são desenhadas para proporcionar resposta rápida às demandas dos clientes. Exemplos: moda rápida (Zara, H&M), Dell (modelo built-to-order), SAMU, atendimento de urgência e emergência, bombeiro.',
    subtext: '',
  },
  {
    id: '125',
    step: '16',
    nameStep: 'Modelo de operações',
    number: '16.2.9',
    titulo: 'Autosserviço',
    text:
      'Considera a adoção do autosserviço como direcionador estratégico de eficiência operacional. A agregação de valor ocorre principalmente por meio da adequada utilização dos recursos. Assim, o usuário executa todo ou parte do processo de atendimento. Exemplos: IKEA, McDonald’s, Viena, Smart Fit.',
    subtext: '',
  },
  {
    id: '126',
    step: '16',
    nameStep: 'Modelo de operações',
    number: '16.2.10',
    titulo: 'Consumidor como produtor',
    text:
      'Considera a adoção do consumidor como produtor como direcionador estratégico de eficiência operacional. A agregação de valor ocorre principalmente por meio da adequada utilização dos recursos. Assim, o usuário executa todo ou parte do processo produtivo. Exemplos: Wikipédia, redes inteligentes de energia (smart grids).',
    subtext: '',
  },
  {
    id: '127',
    step: '16',
    nameStep: 'Modelo de operações',
    number: '16.2.11',
    titulo: 'Ativos compartilhados',
    text:
      'Considera a adoção do modelo de ativos compartilhados como direcionador estratégico de eficiência operacional. A agregação de valor ocorre principalmente por meio da adequada utilização dos recursos. Assim, a propriedade do ativo não é individual, mas compartilhada com outras pessoas ou empresas. Exemplos: Netjets, Royal Thermas Resort.',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 155-159. (# 16 Fractional ownership).',
  },
  {
    id: '128',
    step: '16',
    nameStep: 'Modelo de operações',
    number: '16.2.12',
    titulo: 'Serviços compartilhados',
    text:
      'Considera a adoção do modelo de serviços compartilhados como direcionador estratégico de eficiência operacional. A agregação de valor ocorre principalmente por meio da adequada utilização dos recursos. Assim, o uso do ativo não é individual, mas compartilhado com outras pessoas ou empresas. Exemplos: Car2Go, Zipcar, Yellow, Dress & Go, Uber Pool, WeWork.',
    subtext: '',
  },
  {
    id: '129',
    step: '16',
    nameStep: 'Modelo de operações',
    number: '16.2.13',
    titulo: 'Insumos circulares',
    text:
      'Considera a adoção de insumos circulares como direcionador estratégico de eficiência operacional. A agregação de valor ocorre principalmente por meio da adequada utilização dos recursos. Assim, objetiva considerar os insumos (ex.: energia renovável, insumos biológicos e/ou completamente recuperáveis) no contexto da economia circular. Exemplos: Tesla, Braskem (polietileno verde), empresas de energia solar e eólica.',
    subtext: '',
  },
  {
    id: '130',
    step: '16',
    nameStep: 'Modelo de operações',
    number: '16.2.14',
    titulo: 'Reaproveitamento',
    text:
      'Considera a adoção do reaproveitamento como direcionador estratégico de eficiência operacional. A agregação de valor ocorre principalmente por meio da adequada utilização dos recursos. Assim, objetiva reutilizar (reaproveitar em outros usos) ou reciclar (reprocessamento e produção de novos materiais) os produtos ou insumos dos serviços ao final do ciclo de vida destes. Exemplos: TetraPak, H&M, reciclagem de latas de alumínio.',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 316-320. (# 51 Trash to cash).',
  },
  {
    id: '131',
    step: '16',
    nameStep: 'Modelo de operações',
    number: '16.2.15',
    titulo: 'Venda direta',
    text:
      'Considera a adoção do modelo de venda direta como direcionador estratégico de eficiência operacional. A agregação de valor ocorre principalmente por meio da adequada posição na cadeia de valor. Assim, a entrega é realizada diretamente ao consumidor final por diferentes canais de distribuição controlados pela empresa (comércio eletrônico, lojas próprias, consultoras, venda porta a porta). Exemplos: Amway, Natura, Tupperware, Herbalife, Dell (modelo built-to-order).',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 137-140. (# 12 Direct selling).',
  },
  {
    id: '132',
    step: '16',
    nameStep: 'Modelo de operações',
    number: '16.2.16',
    titulo: 'Sistema puxado',
    text:
      'Considera a adoção do modelo de sistema puxado como direcionador estratégico de eficiência operacional. A agregação de valor ocorre principalmente por meio da adequada posição na cadeia de valor. Assim, a fabricação dos produtos é disparada pela demanda do consumidor final.  Exemplos: Dell (modelo built-to-order), Toyota, Zara.',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 169-173. (# 19 From push to pull).',
  },
  {
    id: '133',
    step: '16',
    nameStep: 'Modelo de operações',
    number: '16.2.17',
    titulo: 'Coordenador da cadeia',
    text:
      'Considera a adoção do modelo de coordenador da cadeia como direcionador estratégico de eficiência operacional. A agregação de valor ocorre principalmente por meio da adequada posição na cadeia de valor. Assim, a empresas foca em algumas competências críticas (ex.: desenvolvimento de produto), e coordena e controla os demais elos de sua cadeia de valor. Exemplos: Apple, Nike, montadoras na indústria automobilística.',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 240-243. (# 34 Orchestrator).',
  },
  {
    id: '134',
    step: '16',
    nameStep: 'Modelo de operações',
    number: '16.2.18',
    titulo: 'Guide shops',
    text:
      'Considera a adoção do modelo de guide shops como direcionador estratégico de eficiência operacional. A agregação de valor ocorre principalmente por meio da adequada posição na cadeia de valor. Assim, os clientes acessam a loja, podem experimentar os produtos, e realizar a compra. Posteriormente, a empresa entrega os produtos na residência dos clientes.  Exemplos: Amaro, concessionárias de automóveis.',
    subtext: '',
  },
  {
    id: '135',
    step: '16',
    nameStep: 'Modelo de operações',
    number: '16.2.19',
    titulo: 'Ponto de venda express',
    text:
      'Considera a adoção do modelo de ponto de venda express como direcionador estratégico de eficiência operacional. A agregação de valor ocorre principalmente por meio da adequada posição na cadeia de valor. Assim, os pontos de venda são pequenas lojas ou quiosques, localizados de forma a aumentar a conveniência dos clientes, e a capilaridade das operações da empresa.  Exemplos: Quiosque Chopp Brahma, Hirota Express em Casa.',
    subtext: '',
  },
  {
    id: '136',
    step: '16',
    nameStep: 'Modelo de operações',
    number: '16.2.20',
    titulo: 'Operações hiperespecializadas',
    text:
      'Considera a adoção do modelo de operações hiperespecializadas como direcionador estratégico de eficiência operacional. A agregação de valor ocorre principalmente por meio da redução da complexidade. Assim, a empresa foca na oferta de um conjunto limitado (ou único) de produtos ou serviços.  Exemplos: Shouldice, Pés Sem Dor, Audibel.',
    subtext: '',
  },
  {
    id: '137',
    step: '17',
    nameStep: 'Modelo de relacionamento com clientes',
    number: '17.1.1',
    titulo: 'Marca do componente',
    text:
      'Considera a marca do componente como direcionador estratégico de intimidade com o cliente. A agregação de valor ocorre principalmente por meio da marca. Assim, a marca do componente aumenta a percepção de valor do produto ou serviço que outra empresa oferece e utiliza esse componente.  Exemplos: Intel, Tetra Pak, Carl Zeiss, Shimano, Lycra, Teflon, Kevlar, Sistema Anglo de Ensino.',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 183-187. (# 22 Ingredient branding).',
  },
  {
    id: '138',
    step: '17',
    nameStep: 'Modelo de relacionamento com clientes',
    number: '17.1.2',
    titulo: 'Marca própria',
    text:
      'Considera a marca própria como direcionador estratégico de intimidade com o cliente. A agregação de valor ocorre principalmente por meio da marca. Assim, os produtos ou serviços são produzidos por uma empresa (produtos sem rótulo ou white label) e comercializados por outra empresa, que utiliza sua própria marca (private label). Exemplos: Foxconn (white label),Richelieu (white label), Pão de Açúcar (diferentes marcas próprias).',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 334-338. (# 55 White label).',
  },
  {
    id: '139',
    step: '17',
    nameStep: 'Modelo de relacionamento com clientes',
    number: '17.1.3',
    titulo: 'Base de dados de clientes',
    text:
      'Considera a utilização da base de dados de clientes como direcionador estratégico de intimidade com o cliente. A agregação de valor ocorre principalmente por meio do relacionamento com os clientes. Assim, a empresa utiliza os dados associados e/ou gerados por seus clientes como fonte de informações para outras empresas. Exemplos: Patients like Me, 23andMe, Google, Facebook.',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 197-201. (# 25 Leverage customer data).',
  },
  {
    id: '140',
    step: '17',
    nameStep: 'Modelo de relacionamento com clientes',
    number: '17.1.4',
    titulo: 'Fidelidade dos clientes',
    text:
      'Considera a fidelidade dos clientes como direcionador estratégico de intimidade com o cliente. A agregação de valor ocorre principalmente por meio do relacionamento com os clientes. Assim, a empresa utiliza incentivos para aumentar a retenção de seus clientes e reduzir sua rotatividade (churn rate). Exemplos: American Airlines, Multiplus.',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 126-130. (# 10 Customer loyalty).',
  },
  {
    id: '141',
    step: '18',
    nameStep: 'Modelo de inovação',
    number: '18.1.1',
    titulo: 'Inovação de valor',
    text:
      'Considera a inovação de valor como direcionador estratégico de inovação. A agregação de valor ocorre principalmente por meio de mudanças na percepção de preço e/ou valor por parte dos clientes. Assim, a empresa desenvolve uma proposta de valor inovadora, que pode contemplar elementos inéditos ou diferentes das dominantes ou tradicionais. Exemplos: Cirque du Soleil, Nintendo, Ice Hotel, André Rieu, Swatch, Smart Fit.',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 94-98. (# 03 Aikido).',
  },
  {
    id: '142',
    step: '18',
    nameStep: 'Modelo de inovação',
    number: '18.1.2',
    titulo: 'Inovação de produtos e serviços',
    text:
      'Considera a inovação de produtos e serviços como direcionador estratégico de inovação. A agregação de valor ocorre principalmente por meio do desenvolvimento de produtos e serviços. Assim, a empresa desenvolve produtos e serviços inovadores, de forma sistematizada. Ex.: Apple, Samsung, Tesla, indústria farmacêutica, empresas de tecnologia, Hospital Albert Einstein.',
    subtext: '',
  },
  {
    id: '143',
    step: '18',
    nameStep: 'Modelo de inovação',
    number: '18.1.3',
    titulo: 'Inovação de processos',
    text:
      'Considera a inovação de processos como direcionador estratégico de inovação. A agregação de valor ocorre principalmente por meio do desenvolvimento de processos. Assim, a empresa desenvolve novos processos produtivos, logísticos, de  entrega e atendimento, de forma sistematizada. Exemplos: Wal Mart (logística), Tesco (loja virtual), China in Box, Itaú, Grupo Fleury, Poupa Tempo, Sem Parar.',
    subtext: '',
  },
  {
    id: '144',
    step: '18',
    nameStep: 'Modelo de inovação',
    number: '18.1.4',
    titulo: 'Inovação de gestão',
    text:
      'Considera a inovação de gestão como direcionador estratégico de inovação. A agregação de valor ocorre principalmente por meio de mudanças no modelo de gestão. Assim, a empresa desenvolve um modelo de gestão inovador, que pode contemplar métodos ou processos de gestão inéditos ou diferentes dos dominantes ou tradicionais. Exemplos: Danaher, GE, Motorola, Semco.',
    subtext: '',
  },
  {
    id: '145',
    step: '18',
    nameStep: 'Modelo de inovação',
    number: '18.1.5',
    titulo: 'Inovação fechada',
    text:
      'Considera o modelo de inovação fechada como direcionador estratégico de inovação. Assim, a empresa desenvolve diferentes tipos de inovação principalmente com recursos próprios. Os resultados desses desenvolvimentos são incorporados nos produtos, serviços e processos da própria empresa. Exemplo: Apple.',
    subtext: '',
  },
  {
    id: '146',
    step: '18',
    nameStep: 'Modelo de inovação',
    number: '18.1.6',
    titulo: 'Colaboração coletiva',
    text:
      'Considera o modelo de colaboração coletiva como direcionador estratégico de inovação. Adota os preceitos da inovação aberta.  Na colaboração coletiva, as ideias e soluções são desenvolvidas de forma colaborativa, com o apoio de pessoas e outras empresas, externas à organização. Exemplos: Procter & Gamble (Connect & Develop), GE (Open Innovation), Innocentive.',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 121-125. (# 09 Crowdsourcing).',
  },
  {
    id: '147',
    step: '18',
    nameStep: 'Modelo de inovação',
    number: '18.1.7',
    titulo: 'Negócio aberto',
    text:
      'Considera o modelo de negócio aberto como direcionador estratégico de inovação. Adota os preceitos da inovação aberta.  No negócio aberto, ocorre uma colaboração entre parceiros de um ecossistema visando a inovação. Em particular,  tem-se o corporate venture quando o engajamento ocorre entre uma empresa e startups. Exemplos: Bradesco (Inovabra), Itaú (Cubo), Hospital Albert Einstein (Eretz.bio).',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 230-234. (# 32 Open business).',
  },
  {
    id: '148',
    step: '18',
    nameStep: 'Modelo de inovação',
    number: '18.1.8',
    titulo: 'Código aberto',
    text:
      'Considera o modelo de código aberto como direcionador estratégico de inovação. Adota os preceitos da inovação aberta.  No código aberto, o código-fonte de uma solução (software) não é restrito à empresa. Esta permite acesso relativamente livre (mediante regras) para outras pessoas e organizações. Geralmente a monetização ocorre por meio de produtos e serviços complementares (ex.: consultoria, treinamento). Exemplos: Opensource.com (Linux), IBM, Mozilla.',
    subtext: '',
  },
  {
    id: '149',
    step: '18',
    nameStep: 'Modelo de inovação',
    number: '18.1.9',
    titulo: 'Engenharia reversa',
    text:
      'Considera o modelo de engenharia reversa como direcionador estratégico de inovação. Adota (em parte) os preceitos da inovação aberta.  Na engenharia reversa, a empresa desenvolve soluções similares àquelas oferecidas por outras empresas. Para tanto, geralmente “desmonta” soluções concorrentes e as recompõe dentro da própria empresa. Exemplo: Byd Auto (China).',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 274-278. (# 42 Reverse engineering).',
  },
  {
    id: '150',
    step: '18',
    nameStep: 'Modelo de inovação',
    number: '18.1.10',
    titulo: 'Inovação reversa',
    text:
      'Considera o modelo de inovação reversa como direcionador estratégico de inovação. Adota os preceitos da inovação aberta.  Na inovação reversa, os produtos e serviços são desenvolvidos em mercados emergentes e oferecidos em países desenvolvidos. Exemplos: GE (Vscan), VW (Gol, Fox), Ford (Ecosport). ',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 279-283. (# 43 Reverse innovation).',
  },
  {
    id: '151',
    step: '18',
    nameStep: 'Modelo de inovação',
    number: '18.1.11',
    titulo: 'Design pelo usuário',
    text:
      'Considera o modelo de design pelo usuário como direcionador estratégico de inovação. Adota os preceitos da inovação aberta.  No design pelo usuário, o consumidor participa do design do produto ou serviço. Ademais, ele pode assumir o papel de usuário, ou participar da comercialização do produto ou serviço. Exemplos: Lego (Design byMe), Under Armour, M&M.',
    subtext:
      'Baseado em: Gassmann, O, Frankenberger, K. & Csik, M. (2014). The business model navigator. Harlow: Pearson. p. 330-333. (# 54 User design).',
  },
  {
    id: '152',
    step: '18',
    nameStep: 'Modelo de inovação',
    number: '18.1.12',
    titulo: 'Design circular',
    text:
      'Considera o modelo de design circular como direcionador estratégico de inovação. Adota os preceitos do projeto eficiente.  No design circular (ou design C2C – Cradle-to-Cradle, do Berço ao Berço), a empresa desenvolve soluções de modo que seus componentes e materiais continuem circulando e contribuindo para a economia. Para tanto, pode adotar o design para: durabilidade (longevidade); serviços (servitização); reuso; e recuperação dos materiais. Exemplo: C&A, CBPak, Philips.',
    subtext: '',
  },
  {
    id: '153',
    step: '18',
    nameStep: 'Modelo de inovação',
    number: '18.1.13',
    titulo: 'Design modular',
    text:
      'Considera o modelo do design modular como direcionador estratégico de inovação. Adota os preceitos do projeto eficiente.  No design modular, os subconjuntos e componentes da solução são projetados como peças independentes, que são conectados por uma interface padrão. Assim, a solução final consiste em montar os módulos. Exemplos: IBM (microcomputador), indústria automotiva (plataformas), componentes de software.',
    subtext: '',
  },
  {
    id: '154',
    step: '18',
    nameStep: 'Modelo de inovação',
    number: '18.1.14',
    titulo: 'Projeto ágil',
    text:
      'Considera o modelo de  projeto ágil (ou de métodos ágeis) como direcionador estratégico de inovação. Adota os preceitos do projeto eficiente.  O projeto ágil contempla um desenvolvimento iterativo e incremental, com a liberação de versões (ou entregas, ou sprints) em curtos períodos de tempo. Assim, o projeto ágil procura uma rápida adaptação ao contexto da organização. Exemplos: Spotify, Nubank.',
    subtext: '',
  },
  {
    id: '155',
    step: '18',
    nameStep: 'Modelo de inovação',
    number: '18.1.15',
    titulo: 'Inovação direcionada por tecnologia',
    text:
      'Considera a inovação direcionada por tecnologia como forma de gerar valor por meio da inovação. Assim, a empresa desenvolve ou adquire uma tecnologia de modo que esta possa ser utilizada ou incorporada, posteriormente, em uma solução para atender uma necessidade dos clientes atuais ou novos. Exemplos: Google (algoritmo de busca por relevância), Nespresso (Nestlé), Tesla (armazenamento de energia).',
    subtext: '',
  },
  {
    id: '156',
    step: '18',
    nameStep: 'Modelo de inovação',
    number: '18.1.16',
    titulo: 'Inovação direcionada pelas necessidades do usuário',
    text:
      'Considera a inovação direcionada pelas necessidades do usuário como forma de gerar valor por meio da inovação. Assim, a empresa compreende as necessidades do usuário e desenvolve ou adquire uma solução visando atender essas necessidades. Exemplo: Smart Fit, Hotel Formule 1, Prevent Senior.',
    subtext: '',
  },
  {
    id: '157',
    step: '18',
    nameStep: 'Modelo de inovação',
    number: '18.1.17',
    titulo: 'Inovação direcionada por reação ao mercado',
    text:
      'Considera a inovação direcionada por reação ao mercado como forma de gerar valor por meio da inovação. Assim, a empresa é caracterizada como seguidora, pois aguarda a inovação dos concorrentes, ou reage posteriormente a ela. Em geral, as soluções são similares àquelas dos concorrentes. Exemplos: indústria farmacêutica (medicamentos me-too ou follow-on), Curves e Contours.',
    subtext: '',
  },
  {
    id: '158',
    step: '19',
    nameStep: 'Modelo de gestão',
    number: '19.1.1',
    titulo: 'Modelo planejamento',
    text:
      'Considera o modelo planejamento como elemento principal do seu modelo de gestão. Assim, a empresa centraliza os fins (definição dos objetivos e formas de motivação dos funcionários) e controla os meios (tomada de decisões e coordenação das atividades). Frase típica: “É uma empresa burocrática”. Exemplos: grandes corporações em geral (ex.: IBM).',
    subtext:
      'Baseado em: Birkinshaw, J. & Goddard, J. (2009). What is your management model?. Sloan Management Review, 50(2), 81-90.',
  },
  {
    id: '159',
    step: '19',
    nameStep: 'Modelo de gestão',
    number: '19.1.2',
    titulo: 'Modelo indagativo',
    text:
      'Considera o modelo indagativo como elemento principal do seu modelo de gestão. Assim, a empresa centraliza os fins (definição dos objetivos e formas de motivação dos funcionários) e descentraliza e/ou delega os meios (tomada de decisões e coordenação das atividades). Frase típica: “Diga aos funcionários o que fazer, mas não como fazer”. Exemplos: novas unidades de negócios de grandes corporações.',
    subtext:
      'Baseado em: Birkinshaw, J. & Goddard, J. (2009). What is your management model?. Sloan Management Review, 50(2), 81-90.',
  },
  {
    id: '160',
    step: '19',
    nameStep: 'Modelo de gestão',
    number: '19.1.3',
    titulo: 'Modelo científico',
    text:
      'Considera o modelo científico como elemento principal do seu modelo de gestão. Assim, a empresa descentraliza e/ou delega os fins (definição dos objetivos e formas de motivação dos funcionários) e controla os meios (tomada de decisões e coordenação das atividades). Frase típica: “É dessa forma que ocorre o progresso científico”. Exemplos: universidades de pesquisa, empresas ou unidades baseadas em pesquisa e desenvolvimento (P&D).',
    subtext:
      'Baseado em: Birkinshaw, J. & Goddard, J. (2009). What is your management model?. Sloan Management Review, 50(2), 81-90.',
  },
  {
    id: '161',
    step: '19',
    nameStep: 'Modelo de gestão',
    number: '19.1.4',
    titulo: 'Modelo descoberta',
    text:
      'Considera o modelo descoberta como elemento principal do seu modelo de gestão. Assim, a empresa descentraliza e/ou delega os fins (definição dos objetivos e formas de motivação dos funcionários) e descentraliza e/ou delegas meios (tomada de decisões e coordenação das atividades). Frase típica: “O sucesso é obtido por tentativa e erro”. Exemplos: startups.',
    subtext:
      'Baseado em: Birkinshaw, J. & Goddard, J. (2009). What is your management model?. Sloan Management Review, 50(2), 81-90.',
  },
];

export default allCardsList;
