import { MainContainer } from './styles';
import { GrBlockQuote } from 'react-icons/gr';
import { Typography } from '@material-ui/core';

const EmptyQuestions = () => {
  return (
    <MainContainer>
      <div>
        <GrBlockQuote
          style={{ display: 'block', margin: 'auto' }}
          color="#dddddd"
          size={150}
        />
        <Typography variant="h5" style={{ color: '#7d7d7f' }}>
          Esse passo não contém questões a serem respondidas
        </Typography>
      </div>
    </MainContainer>
  );
};

export default EmptyQuestions;
