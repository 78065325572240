import { Container, Typography } from "@material-ui/core";
import styled from "styled-components";

export const MainContainer = styled.div`
  box-shadow: 0px 10px 17px -1px rgba(0, 0, 0, 0.18);
  margin-bottom: 3em;
`;

export const CycleTitle = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  padding: 1.7em 0;
  color: white;
  text-align: center;
  background-color: ${({ color }) => color};
`;

export const MicroStepsArea = styled(Container)`
  display: flex;
  justify-content: space-between;
  padding-top: 2em;
  padding-bottom: 2em;
  box-sizing: border-box;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: 30px;
  }
`;

export const Half = styled.div`
  width: 48%;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;
