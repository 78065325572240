import { FaCheck } from 'react-icons/fa';
import { MdLocationOn } from 'react-icons/md';
import StepMicro from '../StepMicro';

const StepContainer = ({ actualStep }) => {
  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', marginBottom: '3em' }}
    >
      <StepMicro
        title="Partida"
        color={actualStep === 0 ? '#428de6' : '#00B3A1'}
      >
        {actualStep === 0 ? <MdLocationOn size={25} /> : <FaCheck size={25} />}
      </StepMicro>
      <div
        style={{
          backgroundColor: '#d8d8d8',
          marginTop: 'calc(2em - 1px)',
          height: '2px',
          width: '2em',
        }}
      />
      <StepMicro
        title="Problema"
        color={
          actualStep === 0 ? '#d8d8d8' : actualStep < 8 ? '#428de6' : '#00b3a1'
        }
      >
        {actualStep > 0 && actualStep < 8 ? (
          <MdLocationOn size={25} />
        ) : (
          actualStep > 7 && <FaCheck size={25} />
        )}
      </StepMicro>
      <div
        style={{
          backgroundColor: '#d8d8d8',
          marginTop: 'calc(2em - 1px)',
          height: '2px',
          width: '2em',
        }}
      />
      <StepMicro
        title="Solução"
        color={
          actualStep < 8 ? '#d8d8d8' : actualStep < 15 ? '#428de6' : '#00b3a1'
        }
      >
        {actualStep > 7 && actualStep < 15 ? (
          <MdLocationOn size={25} />
        ) : (
          actualStep > 14 && <FaCheck size={25} />
        )}
      </StepMicro>
      <div
        style={{
          backgroundColor: '#d8d8d8',
          marginTop: 'calc(2em - 1px)',
          height: '2px',
          width: '2em',
        }}
      />
      <StepMicro
        title="Modelo de negócios"
        color={
          actualStep < 15 ? '#d8d8d8' : actualStep < 23 ? '#428de6' : '#00b3a1'
        }
      >
        {actualStep > 14 && actualStep < 23 ? (
          <MdLocationOn size={25} />
        ) : (
          actualStep > 22 && <FaCheck size={25} />
        )}
      </StepMicro>
    </div>
  );
};

export default StepContainer;
