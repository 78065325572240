import { List } from 'react-content-loader';
import { MainContainer } from './styles';

const Fetching = () => {
  return (
    <MainContainer>
      <List width={800} />;
    </MainContainer>
  );
};

export default Fetching;
