import { Button, Dialog, DialogContent } from '@material-ui/core';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Spacer from '../../../../components/Spacer';
import { BsFillExclamationCircleFill } from 'react-icons/bs';
import { IoShieldCheckmark, IoStar } from 'react-icons/io5';
import { getListOfPendingSteps } from '../../../../utils/stepFunctions';
import {
  ContainerFlex,
  DecisionButtonContainer,
  LessonIconContainer,
  LessonsContainer,
  LessonsResults,
  LessonsTitle,
  LessonsType,
} from './styles';
import useWindowSize from '../../../../hooks/useWindowSize';

const SaveQuestionsModal = ({
  modalOpen,
  setIsOpen,
  stepId,
  questionsList,
  answeredQuestions,
  handleSubmit,
  projectDetails,
}) => {
  const [canEdit, setCanEdit] = useState(true);
  const params = useParams();
  const [alertPendingSteps, setAlertPendingSteps] = useState(
    getListOfPendingSteps(projectDetails, params.id).length > 0
  );
  const {width} = useWindowSize()
  return (
    <Dialog
      open={modalOpen}
      onClose={() => {
        setIsOpen(false);
        setCanEdit(true);
        setAlertPendingSteps(
          getListOfPendingSteps(projectDetails, params.id).length > 0
        );
      }}
      maxWidth
    >
      <DialogContent
        style={{
          padding: '3em',
          background: 'rgba(0,0,0,0.03)',
        }}
      >
        {!!answeredQuestions && !canEdit ? (
          <>
            <LessonsTitle>Respostas enviadas com sucesso!</LessonsTitle>
            <LessonsContainer fullWidth>
              <ContainerFlex preventColumn>
                <IoStar color="green" size={20} />
                <IoStar color="green" size={20} />
                <IoStar color="green" size={20} />
              </ContainerFlex>
              <Spacer />
              <LessonsResults small>
                Você concluiu o <b>Passo {stepId}</b>!
              </LessonsResults>
              <Spacer />
              {['07', '14', '22'].includes(params.id) ? (
                <LessonsType>
                  A sua equipe ganha 100 pontos na Jornada pela conclusão desse
                  passo. Antes disso, um mentor avaliará seu ciclo completo.
                </LessonsType>
              ) : (
                <LessonsType>
                  A sua equipe ganhou 10 pontos na Jornada pela conclusão desse
                  passo.
                </LessonsType>
              )}
            </LessonsContainer>
            <Spacer />
            <ContainerFlex>
              <Button
                onClick={() => {
                  setIsOpen(false);
                  setCanEdit(true);
                }}
              >
                Fechar
              </Button>
            </ContainerFlex>
          </>
        ) : (
          questionsList !== undefined &&
          Array.isArray(questionsList.questionsArray) && (
            <>
              <LessonsTitle>Resultados do passo {stepId}</LessonsTitle>
              <ContainerFlex>
                <LessonsContainer>
                  <LessonsResults>
                    {
                      questionsList.questionsArray.filter(
                        (q) => q.answer === 'Atividade não realizada'
                      ).length
                    }
                  </LessonsResults>
                  <LessonsType>atividades não realizadas</LessonsType>
                  <LessonIconContainer>
                    <BsFillExclamationCircleFill size={32} color="red" />
                  </LessonIconContainer>
                </LessonsContainer>
                <LessonsContainer>
                  <LessonsResults>
                    {
                      questionsList.questionsArray.filter(
                        (q) => q.answer === 'Atividade realizada'
                      ).length
                    }
                  </LessonsResults>
                  <LessonsType>atividades realizadas</LessonsType>
                  <LessonIconContainer>
                    <IoShieldCheckmark size={34} color="green" />
                  </LessonIconContainer>
                </LessonsContainer>
              </ContainerFlex>
            </>
          )
        )}
        {['07', '14', '22'].includes(params.id) && canEdit && (
          <div
            style={{
              maxWidth: 0.8 * width,
              margin: '2em 0',
              boxSizing: 'border-box',
              backgroundColor: '#fcd9d9',
              padding: '1em',
              textAlign: 'center',
            }}
          >
            <strong>AVISO:</strong> Este é um passo de{' '}
            <strong>Validação</strong>. Ao selecionar uma das opções abaixo,
            todas as atividades desenvolvidas neste ciclo serão avaliadas por um
            mentor responsável. Essa avaliação determinará a sequência da sua
            equipe para o próximo ciclo.
            {getListOfPendingSteps(projectDetails, params.id).length > 0 && (
              <>
                <br />
                <br />
                Você ainda possui os seguintes passos sem finalizar:{' '}
                <strong>
                  {getListOfPendingSteps(projectDetails, params.id).join(', ')}
                </strong>
              </>
            )}
          </div>
        )}
        {alertPendingSteps && (
          <LessonsTitle small>
            Deseja continuar mesmo com passos não concluídos nesse ciclo?
          </LessonsTitle>
        )}
        <ContainerFlex>
          {(!answeredQuestions || canEdit) &&
            alertPendingSteps &&
            [
              'Não, eu irei revisar os passos faltantes antes de continuar',
              'Sim, eu desejo enviar meu ciclo para avaliação mesmo com os passos faltantes',
            ].map((a, i) => (
              <DecisionButtonContainer
                onClick={() => {
                  if (i === 0) setIsOpen(false);
                  else setAlertPendingSteps(false);
                }}
              >
                {a}
              </DecisionButtonContainer>
            ))}
        </ContainerFlex>

        {(!answeredQuestions || canEdit) && !alertPendingSteps && (
          <>
            <LessonsTitle>Qual a sua decisão?</LessonsTitle>
            <ContainerFlex>
              {[
                'A decisão é pelo retorno às etapas “Aprender” e “Executar” visando tentar realizar as atividades pendentes',
                'A decisão é pelo prosseguimento na Jornada e concluir as atividades pendentes ao longo dos próximos passos',
                'A decisão é pelo prosseguimento na Jornada uma vez que todas as atividades foram realizadas neste passo',
              ].map((a, i) => (
                <DecisionButtonContainer
                  onClick={() => {
                    if (i === 0) setIsOpen(false);
                    else handleSubmit(setCanEdit);
                  }}
                >
                  {a}
                </DecisionButtonContainer>
              ))}
            </ContainerFlex>
          </>
        )}
        <Spacer size="2em" />
      </DialogContent>
    </Dialog>
  );
};

export default SaveQuestionsModal;
