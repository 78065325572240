import Axios from 'axios';

const api = {
  axios: Axios.create({
    baseURL: 'https://us-central1-jornada-inovacao.cloudfunctions.net/api',
    // baseURL: 'http://localhost:5001/jornada-inovacao/us-central1/api',
    // baseURL: 'http://3744-2804-431-cfc2-c93f-69c3-848e-2ef5-85a0.ngrok.io/jornada-inovacao/us-central1/api',
    // baseURL: 'https://7f0036d8e29b.ngrok.io/jornada-inovacao/us-central1',
  }),
  getStepDetails: async (stepNumber) => {
    const result = await api.axios.get(`/stepDetails/${stepNumber}`);
    return result;
  },
  getAllProjects: async (userId) => {
    const result = await api.axios.get(`/projects/${userId}`);
    return result;
  },
  getProjectDetails: async (projectId) => {
    const result = api.axios.get(`/project-details/${projectId}`);
    return result;
  },
  setInterViewsNumber: async (step, interviews) => {
    const result = api.axios.post(
      `/interviews/${localStorage.getItem('projectId')}/${step}/${interviews}`
    );
    return result;
  },
  registerQuestions: async (step, data) => {
    const config = {
      headers: { 'Content-Type': 'application/json' },
    };
    const result = api.axios.post(
      `/questions/${localStorage.getItem('projectId')}/${step}`,
      data,
      config
    );
    return result;
  },
  registerNewUser: async (data) => {
    const config = {
      headers: { 'Content-Type': 'application/json' },
    };
    const result = await api.axios.post('/register-user', data, config);
    return result;
  },
  login: async (data) => {
    const config = {
      headers: { 'Content-Type': 'application/json' },
    };
    const result = await api.axios.post('/user/login', data, config);
    return result;
  },
  handleSaveCard: async (cardId) => {
    const result = await api.axios.post(
      `/save-card/${localStorage.getItem('projectId')}/${cardId}`
    );
    return result;
  },
};

api.axios.interceptors.response.use(
  (request) => {
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
