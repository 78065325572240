import { Button, TextField, Typography } from '@material-ui/core';
import QuestionContainer from '../../../../components/QuestionContainer';
import EmptyQuestions from '../EmptyQuestions';
import Spacer from '../../../../components/Spacer';
import { ButtonArea, MainContainer, SubTitle, Title } from './styles';
import { useEffect, useState } from 'react';

const DecidingTab = ({
  answeredQuestions,
  setQuestionsListState,
  stepQuestions = [],
  handleAnswerQuestion,
  setModalOpen,
}) => {
  const [questionsList, setQuestionsList] = useState({
    questionsArray: stepQuestions.map((q) => ({
      answer: answeredQuestions
        ? answeredQuestions.questionsArray.find(
            (ques) => ques.title === q.title
          ).answer
        : 'Atividade não realizada',
      title: q.title,
    })),
    observations: answeredQuestions ? answeredQuestions.observations : '',
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setQuestionsListState(questionsList), [questionsList]);
  return (
    <>
      {Array.isArray(stepQuestions) && stepQuestions.length === 0 ? (
        <EmptyQuestions />
      ) : (
        <MainContainer>
          <Title>Ponto de Decisão</Title>
          <SubTitle>
            O processo de inovação requer foco do empreendedor e do time de
            inovação. Aprender, executar e decidir a cada passo de sua Jornada
            tornam-se ações imprescindíveis. <br />
            <br />
            Neste momento, a etapa “Decidir” tem o objetivo de verificar o
            status de realização das atividades deste passo. Não se trata de uma
            avaliação formal, mas de um direcionador importante para a tomada de
            decisão, podendo também levar à reflexão sobre a execução do passo e
            suas conexões com os passos anteriores. Caso seja necessário deixar
            registradas observações quanto às decisões tomadas, o último campo
            desta etapa tem essa finalidade.
          </SubTitle>
          <Spacer size="2em" />
          <>
            <Typography style={{ fontWeight: 500, fontSize: '1.4em' }}>
              Marque as atividades realizadas neste passo
            </Typography>
            <Spacer size="2em" />
            {[...stepQuestions].map((q) => (
              <QuestionContainer
                answeredQuestions={answeredQuestions}
                handleAnswer={(answer, question, title) => {
                  const newQuestions = [...questionsList.questionsArray];
                  newQuestions[question] = { answer, title };
                  setQuestionsList({
                    ...questionsList,
                    questionsArray: newQuestions,
                  });
                }}
                question={q}
              />
            ))}
            <Spacer size="2em" />
            <TextField
              onChange={(e) =>
                setQuestionsList({
                  ...questionsList,
                  observations: e.target.value,
                })
              }
              value={questionsList.observations}
              fullWidth
              multiline
              rows={2}
              variant="outlined"
              placeholder="Observações: "
            />
            {answeredQuestions && (
              <p>
                Questões já respondidas por {answeredQuestions.user} em{' '}
                {answeredQuestions.date}
              </p>
            )}
            <Spacer size="3em" />
            <ButtonArea>
              <Button
                style={{ padding: '1em 3em' }}
                variant="contained"
                color="primary"
                onClick={() => setModalOpen(true)}
              >
                Finalizar preenchimento
              </Button>
            </ButtonArea>
          </>
        </MainContainer>
      )}
    </>
  );
};

export default DecidingTab;
