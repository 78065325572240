import { Typography } from "@material-ui/core";
import useWindowSize from "../../hooks/useWindowSize";
// import './index.css';

const Footer = () => {
  const { width } = useWindowSize();
  // return (
  // 	<div className='footer-main'>
  // 		<Container>
  // 			<div className='footer-items'>
  // 				<div className='footer-col'>
  // 					<h3 className='footer-title'>Contato</h3>
  // 					<h4 className='footer-text'>mpedroso@usp.br</h4>
  // 					<h3 className='footer-title'>Todos os direitos reservados</h3>
  // 					<h4 className='footer-text'>mpedroso@usp.br</h4>
  // 				</div>

  // 				<div className='footer-col'>
  // 					<h3 className='footer-title'>O estudo por trás da jornada</h3>
  // 					<h4 className='footer-text'>Artigo sobre jornada</h4>
  // 					<h3 className='footer-title'>Mestrado em empreendedorismo FEA</h3>
  // 					<h4 className='footer-text'>https://www.fea.usp.br/administracao/pos-graduacao-profissional</h4>
  // 				</div>
  // 			</div>
  // 		</Container>
  // 	</div>
  // )
  return (
    <div
      style={{
        backgroundColor: "#272322",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "150px 20px ",
        textAlign: "center",
      }}
    >
      <Typography
        style={{
          color: "#FECD28",
          textTransform: "uppercase",
          fontWeight: "bold",
          fontSize: "1.2em",
          marginBottom: "1em",
          letterSpacing: "0.1em",
        }}
      >
        Jornada Da Inovação de Modelo de Negócio
      </Typography>
      <Typography
        style={{
          color: "white",
          fontSize: "1.1em",
        }}
      >
        Todos os Direitos Reservados{width < 1024 ? <br /> : " | "}Marcelo
        Caldeira Pedroso
      </Typography>
    </div>
  );
};

export default Footer;
