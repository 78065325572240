import { MainContainer } from './styles';
import { AiOutlineFileUnknown } from 'react-icons/ai';
import { Button, Typography } from '@material-ui/core';
import Spacer from '../../../../components/Spacer';
import { useHistory } from 'react-router';

const InvalidStep = () => {
  const { push } = useHistory();
  return (
    <>
      <Spacer size="20vh" />
      <MainContainer>
        <div>
          <AiOutlineFileUnknown
            style={{ display: 'block', margin: 'auto' }}
            color="#dddddd"
            size={150}
          />
          <Typography variant="h5" style={{ color: '#7d7d7f' }}>
            Passo não encontrado
          </Typography>
          <Spacer />
          <Button
            variant="contained"
            style={{ display: 'block', margin: 'auto' }}
            onClick={() => push('/')}
          >
            Voltar à tela inicial
          </Button>
        </div>
      </MainContainer>
      <Spacer size="20vh" />
    </>
  );
};

export default InvalidStep;
