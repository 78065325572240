import styled from "styled-components";

export const MainContainer = styled.div`
  width: 32%;
  box-shadow: 3px 6px 9px 0px rgba(0, 0, 0, 0.29);
  margin-bottom: 1em;
  background-color: white;

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    width: calc(50% - 20px);
  }

  @media screen and (min-width: 360px) and (max-width: 767px) {
    width: 100%;
  }
`;

export const ButtonAccess = styled.a`
  font-weight: 400;
  text-decoration: none;
  color: black;
  padding: 0.5em 1em;
  border: black 1px solid;
  border-radius: 10px;
  margin-bottom: 2em;
  background-color: #f5f5f5;
  box-shadow: 4px 6px 10px -8px rgba(0, 0, 0, 0.4);
  border: 4px solid #cdcdcd;
`;
