import { Button } from "@material-ui/core";
import { RiBookmarkFill, RiBookmarkLine } from "react-icons/ri";
import useWindowSize from "../../hooks/useWindowSize";

const SavedCard = ({
  card,
  color = "#00B3A1",
  savedCards = [card.id],
  handleSave,
}) => {
  const { width } = useWindowSize();
  return (
    <div
      style={{
        padding: "2em",
        borderRadius: "1em",
        border: `${color} 3px solid`,
        width: width < 678 ? "99%" : width < 1024 ? "49%" : "32%",
        boxSizing: "border-box",
        marginBottom: "2em",
        backgroundColor: "white",
        minHeight: "28em",
        position: "relative",
      }}
    >
      {savedCards.includes(card.id) ? (
        <RiBookmarkFill
          style={{ position: "absolute", top: -6, right: "1em", color }}
          size={40}
        />
      ) : (
        <RiBookmarkLine
          style={{ position: "absolute", top: -6, right: "2em", color }}
          size={40}
        />
      )}
      <h2 style={{ color: color }}>
        {card.number} {card.titulo}
      </h2>
      <p>{card.text}</p>
      <p>{card.subtext}</p>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {!savedCards.includes(card.id) && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleSave(card.id)}
          >
            Salvar
          </Button>
        )}
      </div>
    </div>
  );
};

export default SavedCard;
