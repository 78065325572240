import { MainContainer } from './styles';
import { AiOutlineFileSearch } from 'react-icons/ai';
import { Button, Typography } from '@material-ui/core';
import Spacer from '../../../../components/Spacer';
import { useHistory } from 'react-router';

const NoProject = () => {
  const { push } = useHistory();
  return (
    <MainContainer>
      <div>
        <AiOutlineFileSearch
          color="#dddddd"
          size={150}
          style={{ display: 'block', margin: 'auto' }}
        />
        <Spacer />
        <Typography variant="h5" style={{ color: '#7d7d7f' }}>
          Os detalhes desse projeto não foram encontrados :(
        </Typography>
        <Spacer size="2em" />
        <Button
          variant="contained"
          style={{ display: 'block', margin: 'auto' }}
          onClick={() => push('/')}
        >
          Voltar à tela inicial
        </Button>
      </div>
    </MainContainer>
  );
};

export default NoProject;
