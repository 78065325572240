import { MainContainer } from './styles';
import { GiStopSign } from 'react-icons/gi';
import { Button, Typography } from '@material-ui/core';
import Spacer from '../../../../components/Spacer';
import { useHistory, useParams } from 'react-router';

const ForbiddenStep = () => {
  const { push } = useHistory();
  const { id } = useParams();
  return (
    <>
      <Spacer size="20vh" />
      <MainContainer>
        <div>
          <GiStopSign
            style={{ display: 'block', margin: 'auto' }}
            color="#dddddd"
            size={150}
          />
          <Spacer size="2em" />
          <Typography
            variant="h6"
            style={{ color: '#727272', textAlign: 'center', fontSize: '2em' }}
          >
            Passo {id} ainda não disponível
          </Typography>
          <Spacer size="2em" />
          <Typography
            variant="h6"
            style={{ color: '#afafaf', textAlign: 'center' }}
          >
            Para continuar seguindo a jornada, aguarde um administrador validar
            os passos anteriores do projeto{' '}
            {localStorage.getItem('projectName')}
          </Typography>
          <Spacer size="2em" />
          <Button
            variant="contained"
            style={{ display: 'block', margin: 'auto' }}
            onClick={() => push('/')}
          >
            Voltar à tela inicial
          </Button>
        </div>
      </MainContainer>
      <Spacer size="20vh" />
    </>
  );
};

export default ForbiddenStep;
