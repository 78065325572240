import { Button } from '@material-ui/core';
import { useHistory } from 'react-router';
import { sliceText } from '../../utils/textFunctions';
import Spacer from '../Spacer';
import { GoPrimitiveDot } from 'react-icons/go';
import {
  LeftContainer,
  MainContainer,
  ProjectName,
  RightContainer,
  ProjectDescription,
  ProjectConclusion,
} from './styles';

const ClassCard = ({ details, order }) => {
  const { push } = useHistory();

  return (
    <MainContainer order={order}>
      <LeftContainer>
        <ProjectName>{details.name}</ProjectName>
        <Spacer />
        <ProjectDescription>
          {sliceText(details.description)}
        </ProjectDescription>
        <Spacer size="2em" />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            localStorage.setItem('projectName', details.name);
            localStorage.setItem('projectId', details.id);
            push(`/project/${details.id}`);
          }}
        >
          Acessar
        </Button>
      </LeftContainer>
      <RightContainer>
        <div>
          <ProjectConclusion>
            {details.conclusion.replace('.', ',')}%
          </ProjectConclusion>
          <div>
            <GoPrimitiveDot
              size={22}
              color={details.actualStep === 0 ? '#418de6' : '#00b3a1'}
            />
            <GoPrimitiveDot
              size={22}
              color={
                details.actualStep === 0
                  ? '#dcdcdc'
                  : details.actualStep < 8
                  ? '#418de6'
                  : '#00b3a1'
              }
            />
            <GoPrimitiveDot
              size={22}
              color={
                details.actualStep < 8
                  ? '#dcdcdc'
                  : details.actualStep < 15
                  ? '#418de6'
                  : '#00b3a1'
              }
            />
            <GoPrimitiveDot
              size={22}
              color={
                details.actualStep < 15
                  ? '#dcdcdc'
                  : details.actualStep < 23
                  ? '#418de6'
                  : '#00b3a1'
              }
            />
          </div>
        </div>
      </RightContainer>
    </MainContainer>
  );
};

export default ClassCard;
