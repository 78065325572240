import { Container, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const MainContainer = styled(Container)``;

export const Title = styled(Typography)`
  font-weight: 600;
  font-size: 1.7em;
`;

export const SubTitle = styled(Typography)``;

export const ButtonArea = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const QuestionTitle = styled(Typography)`
  font-size: 1.4em;
  font-weight: 500;
`;

export const StepConcludedText = styled(Typography)``
