import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router';

const AdminApproval = () => {
  const { push } = useHistory();
  return (
    <div
      style={{
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#e1e1e1',
      }}
    >
      <div
        style={{
          width: '50%',
          borderRadius: 20,
          minWidth: 450,
          maxWidth: 700,
          backgroundColor: 'white',
          padding: '4em',
          boxSizing: 'border-box',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img
            src="/admin-aproval.png"
            alt=""
            style={{ margin: 0, width: '30%' }}
          />
        </div>
        <Typography
          style={{
            textAlign: 'center',
            fontWeight: 500,
            fontSize: '2em',
            marginTop: '0.6em',
            marginBottom: '0.6em',
          }}
        >
          Aguardando aprovação
        </Typography>
        <Typography
          style={{
            textAlign: 'center',
          }}
        >
          Seu cadastro está sendo avaliado por um administrador. Quando
          aprovado, você receberá um email em{' '}
          <Typography
            style={{ display: 'inline', color: '#00B3A1', fontWeight: 600 }}
          >
            {sessionStorage.getItem('userEmail')}
          </Typography>{' '}
          com as instruções de acesso ao sistema.
        </Typography>
        <Typography
          onClick={() => push('/')}
          style={{
            textAlign: 'center',
            marginTop: '2em',
            cursor: 'pointer',
            color: 'gray',
            fontSize: '0.9em',
          }}
        >
          Voltar à página inicial
        </Typography>
      </div>
    </div>
  );
};

export default AdminApproval;
