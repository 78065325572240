import { useEffect, useState } from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import ClassCard from '../../components/ProjectCard';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import PageTitle from '../../components/PageTitle';
import api from '../../services/api';
import EmptyProjects from './components/EmptyProjects';
import LoadingProjects from './components/LoadingProjects';
import { MainContainer } from './styles';

const AllProjects = () => {
  const [allProjects, setAllProjects] = useState([]);
  const [fetchingProjects, setFetchingProjects] = useState(true);
  // const [hasError, setHasError] = useState(false);

  const fetchClasses = () => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    api
      .getAllProjects(userInfo.id)
      .then((res) => {
        setAllProjects(res.data);
        setFetchingProjects(false);
      })
      .catch((er) => console.log(er));
  };

  useEffect(fetchClasses, []);

  return (
    <>
      <Header />
      <Breadcrumb locations={[{ name: 'Projetos', path: '/' }]} />
      <PageTitle title="Todos os seus projetos" />
      <MainContainer
        fetching={fetchingProjects}
        projectsNumber={allProjects.length}
      >
        {fetchingProjects ? (
          <LoadingProjects />
        ) : allProjects.length === 0 ? (
          <EmptyProjects />
        ) : (
          allProjects.map((c, i) => <ClassCard order={i} details={c} />)
        )}
      </MainContainer>
      <Footer />
    </>
  );
};

export default AllProjects;
