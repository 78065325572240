import { IconContainer, MainContainer, TabTitle } from './styles';
import { IoIosArrowDroprightCircle } from 'react-icons/io';

const TabStep = ({ title, onClick, hasIcon, ...props }) => {
  return (
    <MainContainer onClick={onClick} {...props}>
      <TabTitle>{title}</TabTitle>
      {hasIcon && (
        <IconContainer>
          <IoIosArrowDroprightCircle color="white" size={28}/>
        </IconContainer>
      )}
    </MainContainer>
  );
};

export default TabStep;
