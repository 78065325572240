import StepLine from '../StepLine';
import { BiLock } from 'react-icons/bi';
import { CycleTitle, Half, MainContainer, MicroStepsArea } from './styles';

const StepSection = ({ step, actualStep, projectDetails }) => {
  return (
    <MainContainer>
      <CycleTitle color={step.color} variant="h5">
        {step.name}
        {((step.initialStep === 8 && !projectDetails.validations.val1) ||
          (step.initialStep === 15 && !projectDetails.validations.val2) ||
          step.initialStep === 23) && (
          <>
            {'  '}
            <BiLock />
          </>
        )}
      </CycleTitle>
      <MicroStepsArea>
        <Half>
          <img alt="Passo Imagem" src={step.img} />
        </Half>
        <Half>
          {step.steps.map((line, index) => (
            <StepLine
              actualStep={actualStep}
              initialStep={step.initialStep}
              line={line}
              index={index}
              projectDetails={projectDetails}
            />
          ))}
        </Half>
      </MicroStepsArea>
    </MainContainer>
  );
};

export default StepSection;
