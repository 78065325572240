import ToolCard from '../ToolCard';
import { MainContainer } from './styles';

const MaterialsContainer = ({ materials, path }) => {
  return (
    <MainContainer>
      {Array.isArray(materials) &&
        materials.length > 0 &&
        materials.map((t) => <ToolCard tool={t} path={path} />)}
    </MainContainer>
  );
};

export default MaterialsContainer;
