import { /* Button , */ Container /*, TextField */ } from '@material-ui/core';
// import { useState } from 'react';
import MaterialsContainer from '../../../../components/MaterialsContainer';
import SavedCard from '../../../../components/SavedCard';
import SectionTitle from '../../../../components/SectionTitle';
import Spacer from '../../../../components/Spacer';
import { getColorOfStep } from '../../../../utils/stepFunctions';
import VideosContainer from '../VideosContainer';
// import {
//   InterviewsColunm,
//   InterviewsContainer,
//   InterviewsTitle,
//   SectionContainer,
//   InterviewsNumber,
//   InterviewsButtonContainer,
// } from './styles';

const ExecutingTab = ({
  selectedVideo,
  listVideos,
  setSelectedVideo,
  // interviewsNumber,
  // interviewsOnThisStep,
  // setInterviewsNumber,
  // handleSubmitInterviews,
  stepConfig,
  // interviewsToSubmit,
  cards,
  // handleSaveCard,
  // projectDetails,
}) => {
  // const [focus, setFocus] = useState(false);
  return (
    <>
      {Array.isArray(listVideos) && listVideos.length > 0 && (
        <Container>
          <VideosContainer
            selectedVideo={selectedVideo}
            setSelectedVideo={setSelectedVideo}
            videos={listVideos}
          />
        </Container>
      )}

      <Spacer size="6em" />

      {stepConfig.files.executar.length > 0 && (
        <>
          {stepConfig.files.executar.filter((m) => m.type === 'ap').length >
            0 && (
            <>
              <SectionTitle center>Materiais de apoio</SectionTitle>
              <Spacer size="4em" />
              <MaterialsContainer
                materials={stepConfig.files.executar.filter(
                  (m) => m.type === 'ap'
                )}
                path={`arquivos/${stepConfig.path}/executar`}
              />
              <Spacer size="5em" />
            </>
          )}
          {stepConfig.files.executar.filter((m) => m.type === 'ar').length >
            0 && (
            <>
              <SectionTitle center>Ferramentas</SectionTitle>
              <Spacer size="4em" />
              <MaterialsContainer
                materials={stepConfig.files.executar.filter(
                  (m) => m.type === 'ar'
                )}
                path={`arquivos/${stepConfig.path}/executar`}
              />
              <Spacer size="5em" />
            </>
          )}
        </>
      )}
      {/* <SectionContainer>
        <Spacer size="4em" />
        <SectionTitle center>Entrevistas</SectionTitle>
        <Spacer size="4em" />
        <InterviewsContainer>
          <InterviewsColunm focus={focus}>
            <InterviewsTitle>
              Quantidade de entrevistas realizadas no Passo {stepConfig.name}
            </InterviewsTitle>
            {interviewsOnThisStep === 0 ? (
              <TextField
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                fullWidth
                error={
                  !/^[0-9]+$/.test(interviewsToSubmit) &&
                  interviewsToSubmit !== ''
                }
                placeholder="Quantidade de entrevistas"
                variant="outlined"
                value={interviewsToSubmit}
                style={{
                  backgroundColor: 'white',
                }}
                onChange={(e) => setInterviewsNumber(e.target.value)}
              />
            ) : (
              <InterviewsNumber>{interviewsOnThisStep}</InterviewsNumber>
            )}
          </InterviewsColunm>
          <InterviewsColunm>
            <InterviewsTitle>Entrevistas já realizadas</InterviewsTitle>
            <InterviewsNumber>{interviewsNumber}</InterviewsNumber>
          </InterviewsColunm>
          <InterviewsColunm>
            <InterviewsTitle>Meta de entrevistas</InterviewsTitle>
            <InterviewsNumber>60</InterviewsNumber>
          </InterviewsColunm>
        </InterviewsContainer>
        {interviewsOnThisStep === 0 && (
          <>
            <Spacer size="2em" />
            <InterviewsButtonContainer>
              <Button
                color="primary"
                disabled={
                  !/^[0-9]+$/.test(interviewsToSubmit) ||
                  parseInt(interviewsToSubmit) <= 0
                }
                variant="contained"
                onClick={handleSubmitInterviews}
              >
                Atualizar
              </Button>
            </InterviewsButtonContainer>
          </>
        )}
        <Spacer size="5em" />
      </SectionContainer> */}
      <div
        style={{
          backgroundColor:
            stepConfig.files.executar.length > 0 ? '#d8d8d8' : 'white',
        }}
      >
        {cards.length > 0 && (
          <Container>
            <h2 style={{ textAlign: 'center', padding: '2em' }}>Padrões</h2>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              }}
            >
              {cards.map((c) => (
                <SavedCard
                  // handleSave={handleSaveCard}
                  // savedCards={projectDetails.savedCards}
                  savedCards={[]}
                  handleSave={() => {}}
                  card={c}
                  color={getColorOfStep(stepConfig.number)}
                />
              ))}
            </div>
          </Container>
        )}
      </div>
    </>
  );
};

export default ExecutingTab;
