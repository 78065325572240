import { Button, TextField, Typography } from '@material-ui/core';
import { useState } from 'react';
import { useHistory } from 'react-router';
import api from '../../services/api';

const Login = () => {
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [userInactive, setUserInactive] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { push } = useHistory();

  const fetchLogin = () => {
    const data = {
      email,
      pass,
    };
    setIsFetching(true);
    setUserInactive(false);
    setHasError(false);
    api
      .login(data)
      .then((res) => {
        if (res.data === null) setHasError(true);
        else if (!res.data.data.isActive) {
          setUserInactive(true);
        } else {
          localStorage.setItem('userInfo', JSON.stringify(res.data));
          // eslint-disable-next-line no-restricted-globals
          location.href = '/';
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsFetching(false);
      });
  };

  return (
    <div
      style={{
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#e1e1e1',
      }}
    >
      <div
        style={{
          width: '30%',
          minWidth: 450,
          backgroundColor: 'white',
          padding: '3em',
          boxSizing: 'border-box',
        }}
      >
        <TextField
          required
          label="Email"
          // error={email === '' ? false : !/^[^\s@]+@[^\s@]+$/.test(email)}
          variant="outlined"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          style={{ display: 'block', marginBottom: '1.5em' }}
          fullWidth
          //disabled={isFetching}
        />
        <TextField
          required
          type="password"
          label="Senha"
          variant="outlined"
          onChange={(e) => setPass(e.target.value)}
          value={pass}
          style={{ display: 'block', marginBottom: '1.5em' }}
          fullWidth
          //disabled={isFetching}
        />
        {hasError && !isFetching && (
          <Typography
            style={{
              textAlign: 'center',
              marginBottom: '2em',
              cursor: 'pointer',
              color: 'red',
              fontSize: '0.9em',
            }}
          >
            Usuário ou senha incorretos
          </Typography>
        )}
        {userInactive && !isFetching && (
          <Typography
            style={{
              textAlign: 'center',
              marginBottom: '2em',
              cursor: 'pointer',
              color: 'blue',
              fontSize: '0.9em',
            }}
          >
            Seu usuário ainda não foi validado por um administrador
          </Typography>
        )}
        <Button
          fullWidth
          size="large"
          disabled={isFetching || !email || !pass || pass.length < 8}
          onClick={fetchLogin}
        >
          ACESSAR
        </Button>
        <Typography
          onClick={() => push('/register')}
          style={{
            textAlign: 'center',
            marginTop: '2em',
            cursor: 'pointer',
            color: 'gray',
            fontSize: '0.9em',
          }}
        >
          Não tem conta, clique aqui para se cadastrar
        </Typography>
      </div>
    </div>
  );
};

export default Login;
