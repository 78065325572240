import { Container, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const MainContainer = styled(Container)``;

export const StepTitle = styled(Typography)`
  color: ${({ color }) => color};
  font-size: 2em;
  font-weight: 600;
`;
