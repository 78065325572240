import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const QuestionTitle = styled(Typography)`
  font-size: 1.3em;
  font-weight: 500;
`;

export const QuestionButton = styled.div`
  width: 48%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  padding: 1em 1em;
  box-sizing: border-box;
  box-shadow: 4px 6px 10px -8px rgba(0, 0, 0, 0.52);
  border-radius: 16;
  transition: all 0.1s cubic-bezier(1, 0, 0, 1);
  border: 1px solid rgba(0, 0, 0, 0.3);
  background: ${({ selected, answer }) =>
    selected === answer ? 'rgba(0,0,0,0.1)' : 'white'};
  &:hover {
    background-color: rgba(0,0,0,0.03);
    transform: scale(1.03);
  }
`;

export const QuestionIconContainer = styled.div`
  position: absolute;
  right: -5px;
  top: -5px;
`;
