import { Container } from '@material-ui/core';
import styled from 'styled-components';

export const MainContainer = styled(Container)`
  margin-bottom: ${({ fetching, projectsNumber }) =>
    fetching ? '20vh' : projectsNumber === 0 ? '21vh' : '15vh'};
  transition: all 0.3s ease-in;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
