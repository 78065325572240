/* eslint-disable no-restricted-globals */
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import AdminApproval from './pages/AdminApproval';
import AllProjects from './pages/AllProjects';
import GameLobby from './pages/GameLobby';
import Home from './pages/Home/HomeWithLogin';
import HowToInovate from './pages/HowToInovate';
import Login from './pages/Login';
import Register from './pages/Register';
import SavedCards from './pages/SavedCards';
import Step from './pages/Step';
import Ambition from './pages/Ambition';

const PublicRoutes = () => {
  return (
    <>
      <Route exact path="/" component={Home} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/login" component={Login} />
      <Route path="/pending" component={AdminApproval} />
    </>
  );
};

const App = () => {
  if (!sessionStorage.getItem('active')) {
    sessionStorage.setItem('active', true);
    location.href = '/';
  }
  const user = localStorage.getItem('userInfo');
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          {user ? (
            <>
              <div
                style={{
                  position: 'fixed',
                  bottom: '2em',
                  right: '2em',
                  zIndex: 999,
                }}
              ></div>
              <Route exact path="/" component={AllProjects} />
              <Route path="/project/:id" component={GameLobby} />
              <Route exact path="/ambition" component={Ambition} />
              <Route path="/step/:id" component={Step} />
              <Route path="/saved" component={SavedCards} />
              <Route path="/inovate" component={HowToInovate} />
              <Route path="/home" component={AllProjects} />
            </>
          ) : (
            <PublicRoutes />
          )}
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

export default App;
