import {
  amareloJornada,
  azulJornada,
  roxoJornada,
  // successGreen,
} from "./colors";

export const getColorOfStep = (step) => {
  const newNumber = parseInt(step);
  if ([14, 7, 22].includes(newNumber)) return "#ee5b55";
  // if (step === '00') return successGreen;
  if (newNumber < 7) return azulJornada;
  if (newNumber < 14) return roxoJornada;
  if (newNumber < 27) return amareloJornada;
  return "green";
};

export const stepIsValid = (step) => {
  const newStep = parseInt(step);
  if (step.toString().length !== 2) return false;
  if (!Number.isInteger(newStep)) return false;
  if (newStep >= 0 && newStep <= 18) return true;
  return false;
};

export const canRenderThisStep = (stepNumber, validations) => {
  if (parseInt(stepNumber) > 7 && !validations.val1) return false;
  else if (parseInt(stepNumber) > 14 && !validations.val2) return false;
  else if (parseInt(stepNumber) > 22 && !validations.val3) return false;
  return true;
};

export const getMicroStepsList = (
  stepNumber,
  validations = { val1: false, val2: false, val3: false },
  width
) => {
  const { val1, val2, val3 } = validations;
  let problem = ["01", "02", "03", "04", "05", "06", "07"];
  let solution = ["08", "09", "10", "11", "12", "13", "14"];
  let business = ["15", "16", "17", "18"];
  if (problem.includes(stepNumber)) {
    if (val1) problem = [...problem, "08"];
    if (width < 1024) {
      if (problem.indexOf(stepNumber) === 0) {
        return [problem[0], problem[1], problem[2]];
      }
      if (problem.indexOf(stepNumber) === problem.length - 1) {
        return [
          problem[problem.length - 3],
          problem[problem.length - 2],
          problem[problem.length - 1],
        ];
      }
      return [
        problem[problem.indexOf(stepNumber) - 1],
        problem[problem.indexOf(stepNumber)],
        problem[problem.indexOf(stepNumber) + 1],
      ];
    }
    return problem;
  }
  if (solution.includes(stepNumber)) {
    if (val2) solution = [...solution, "15"];
    solution = ["07", ...solution];
    if (width < 1024) {
      if (solution.indexOf(stepNumber) === 0) {
        return [solution[0], solution[1], solution[2]];
      }
      if (solution.indexOf(stepNumber) === solution.length - 1) {
        return [
          solution[solution.length - 3],
          solution[solution.length - 2],
          solution[solution.length - 1],
        ];
      }
      return [
        solution[solution.indexOf(stepNumber) - 1],
        solution[solution.indexOf(stepNumber)],
        solution[solution.indexOf(stepNumber) + 1],
      ];
    }
    return solution;
  }
  if (business.includes(stepNumber)) {
    if (val3) business = [...business];
    business = ["14", ...business];
    if (width < 1024) {
      if (business.indexOf(stepNumber) === 0) {
        return [business[0], business[1], business[2]];
      }
      if (business.indexOf(stepNumber) === business.length - 1) {
        return [
          business[business.length - 3],
          business[business.length - 2],
          business[business.length - 1],
        ];
      }
      return [
        business[business.indexOf(stepNumber) - 1],
        business[business.indexOf(stepNumber)],
        business[business.indexOf(stepNumber) + 1],
      ];
    }
    return business;
  }
};

export const getListOfPendingSteps = (projectDetails, id) => {
  const pendingSteps = projectDetails.steps
    .map((s) => ({
      stepNumber: s.stepNumber,
      pending: !s.questions,
    }))
    .filter((step) => {
      if (!step.pending || step.stepNumber === 3) return false;
      if (parseInt(id) === 7) return parseInt(step.stepNumber) < parseInt(id);
      if (parseInt(id) === 14)
        return (
          parseInt(step.stepNumber) < parseInt(id) &&
          parseInt(step.stepNumber) > 7
        );
      if (parseInt(id) === 22)
        return (
          parseInt(step.stepNumber) < parseInt(id) &&
          parseInt(step.stepNumber) > 14
        );
      return false;
    })
    .map((s) => s.stepNumber)
    .map((s) => (parseInt(s) < 10 ? "0" + s : "" + s));
  return pendingSteps;
};
