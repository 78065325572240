import { Button, Chip, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Breadcrumb from '../../components/Breadcrumb';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import api from '../../services/api';
import { MdGamepad } from 'react-icons/md';
import { steps } from '../../utils/stepsList';
import NoProject from './components/NoProject';
import Fetching from '../../components/Fetching';
import Spacer from '../../components/Spacer';
import {
  HalfHeader,
  ProjectHeader,
  ProjectScore,
  ProjectTitle,
} from './styles';
import StepContainer from './components/StepContainer';
import StepSection from './components/StepSection';

const GameLobby = () => {
  const [projectDetails, setProjectDetails] = useState(false);
  const [fetchingDetails, setFetchingDetails] = useState(true);
  const [hasError, setHasError] = useState(false);
  const { push } = useHistory();
  const { id } = useParams();

  const fetchProjectDetails = () => {
    setFetchingDetails(true);
    setHasError(false);
    api
      .getProjectDetails(id)
      .then((res) => setProjectDetails(res.data))
      .catch(() => setHasError(true))
      .finally(() => setFetchingDetails(false));
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchProjectDetails, []);
  return (
    <>
      <Header />
      {fetchingDetails ? (
        <Fetching />
      ) : hasError ? (
        <NoProject />
      ) : (
        <>
          <Breadcrumb
            locations={[
              { name: 'Projetos', path: '/' },
              { name: projectDetails.name, path: `/project/${id}` },
            ]}
          />
          <Spacer size="3em" />
          <ProjectHeader>
            <HalfHeader>
              <Chip avatar={<MdGamepad />} label="Game Lobby" />
              <Spacer size="0.3em" />
              <ProjectTitle>{projectDetails.name}</ProjectTitle>
              <Spacer size="2em" />
              <Typography variant="body1">
                {projectDetails.description}
              </Typography>
              <Typography variant="body1">
                <b>Integrantes: </b>
                {projectDetails.members.map((m) => m.name).join(', ')}
              </Typography>
              <Spacer size="2em" />
              <Typography variant="body1">Pontos acumulados:</Typography>
              <Spacer size="0.3em" />
              <ProjectScore size="2.3em">
                {projectDetails.score.toFixed(0)}
              </ProjectScore>
              <ProjectScore> / 1000</ProjectScore>
              <Spacer />
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={() => {
                  const stepNext =
                    parseInt(projectDetails.actualStep) < 10
                      ? '0' + projectDetails.actualStep
                      : projectDetails.actualStep;
                  push('/step/' + stepNext);
                }}
              >
                {projectDetails.actualStep === 0 ? 'Iniciar' : 'Continuar'}
              </Button>
            </HalfHeader>
            <HalfHeader>
              <StepContainer actualStep={projectDetails.actualStep} />
            </HalfHeader>
          </ProjectHeader>
          <Spacer size="4em" />
          {steps.map((s) => (
            <StepSection
              step={s}
              actualStep={projectDetails.actualStep}
              projectDetails={projectDetails}
            />
          ))}
        </>
      )}
      <Footer />
    </>
  );
};

export default GameLobby;
