import { getColorOfStep } from '../../../../utils/stepFunctions';
import TabStep from '../TabStep';
import { MainContainer } from './styles';

const TabSelector = ({ onSelect, stepId, tabSelected }) => {
  return (
    <MainContainer>
      {['Aprender', 'Executar'].map((tab, index) => (
        <TabStep
          hasIcon={index < 1}
          title={tab}
          onClick={() => onSelect(index)}
          color={getColorOfStep(stepId)}
          selected={tabSelected === index}
        />
      ))}
    </MainContainer>
  );
};

export default TabSelector;
