import { Container } from '@material-ui/core';

const PageTitle = ({ title, top, children }) => {
  return (
    <Container
      style={{
        paddingBottom: '2em',
        paddingTop: top ? '2em' : 0,
        display: 'flex',
        justifyContent: 'space-between',
        alignContent: 'center',
      }}
    >
      <h1>{title}</h1>
      {children}
    </Container>
  );
};

export default PageTitle;
