import { Typography } from "@material-ui/core";
import YouTube from "react-youtube";
import SectionTitle from "../../../../components/SectionTitle";
import Spacer from "../../../../components/Spacer";
import YoutubeThumb from "../../../../components/YoutubeThumb";
import { MdCloudDownload } from "react-icons/md";
import {
  DownloadButton,
  MainVideoArea,
  ThumbsArea,
  IconDownloadContainer,
} from "./styles";
import { getColorOfStep } from "../../../../utils/stepFunctions";
import { useParams } from "react-router";
import useWindowSize from "../../../../hooks/useWindowSize";

const VideosContainer = ({ selectedVideo, videos, setSelectedVideo }) => {
  const { width } = useWindowSize();
  const { id } = useParams();
  return (
    <>
      <MainVideoArea>
        {selectedVideo && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <YouTube
              videoId={selectedVideo.id}
              opts={{
                width: width < 1024 ? `${width * 0.9}` : "640",
                height: width < 1024 ? `${width * 0.6}` : "390",
              }}
            />
          </div>
        )}
        <div style={{ width: 1, height: 1 }}></div>
        <a
          style={{ textDecoration: "none", color: "black" }}
          href={selectedVideo.url}
          target="_blank"
          rel="noreferrer"
        >
          <DownloadButton color={getColorOfStep(id)}>
            <IconDownloadContainer>
              <MdCloudDownload size={39} color={getColorOfStep(id)} />
            </IconDownloadContainer>
            <Typography style={{ textAlign: "center" }}>
              Material de apoio
            </Typography>
            <Typography
              style={{
                marginTop: "0.2em",
                textAlign: "center",
                fontSize: "1.3em",
                fontWeight: 600,
              }}
            >
              {selectedVideo.name}
            </Typography>
          </DownloadButton>
        </a>
      </MainVideoArea>
      <Spacer size="3em" />
      {Array.isArray(videos) && videos.length > 1 && (
        <>
          <SectionTitle>Vídeos de apoio</SectionTitle>
          <Spacer size="3em" />
          <ThumbsArea>
            {videos.map((v, i) => (
              <YoutubeThumb
                onClick={setSelectedVideo}
                selected={selectedVideo}
                length={videos.length}
                videoId={v}
                index={i}
              />
            ))}
          </ThumbsArea>
        </>
      )}
    </>
  );
};

export default VideosContainer;
