import { Container, Typography } from "@material-ui/core";
import styled from "styled-components";

export const SectionContainer = styled.div`
  background-color: #d8d8d8;
`;
export const InterviewsContainer = styled(Container)`
  display: flex;
  justify-content: space-between;

  @media screen and (min-width: 360px) and (max-width: 767px) {
    flex-direction: column;
  }

  @media screen and (max-width: 359px) {
    flex-direction: column;
  }
`;

export const InterviewsButtonContainer = styled(Container)`
  display: flex;
  justify-content: center;
`;

export const InterviewsColunm = styled.div`
  box-sizing: border-box;
  width: 30%;
  border-radius: 1em;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 2em;
  transition: all 0.2s ease-in;
  box-shadow: 4px 6px 10px -8px rgba(0, 0, 0, 0.52);
  transform: ${({ focus }) => focus && "scale(1.1)"};

  @media screen and (min-width: 360px) and (max-width: 767px) {
    width: 100%;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 359px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const InterviewsTitle = styled(Typography)`
  font-size: 1.3em;
  font-weight: 600;
  text-align: center;
  margin-bottom: 1em;
`;

export const InterviewsNumber = styled(Typography)`
  font-size: 1.3em;
  font-weight: 600;
  background-color: rgba(255, 255, 255, 0.4);
  text-align: center;
`;
